import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import formStyles from '../../../../../collums-constants/styles/stylesheets/formStyles';
import { withStyles } from '@material-ui/core';
import { useForms } from '../../../../hooks/forms';

const ImageWidget = ({ classes, widgetProps }) => {
    const { isJourney, isReadOnly } = useForms();
    const [imgAlignment, setImgAlignment] = useState(classes.imgLeftAligned);
    const [imgSize, setImgSize] = useState(classes.imgMediumSize);

    useEffect(() => {
        switch (widgetProps.alignment) {
            case 'Left':
                setImgAlignment(classes.imgLeftAligned);
                break;
            case 'Center':
                setImgAlignment(classes.imgCenterAligned);
                break;
            case 'Right':
                setImgAlignment(classes.imgRightAligned);
                break;
            default:
                setImgAlignment(classes.imgLeftAligned);
                break;
        }
    }, [classes.imgCenterAligned, classes.imgLeftAligned, classes.imgRightAligned, widgetProps.alignment]);
    useEffect(() => {
        switch (widgetProps.image_size) {
            case 'Small':
                setImgSize(classes.imgSmallSize);
                break;
            case 'Medium':
                setImgSize(classes.imgMediumSize);
                break;
            case 'Big':
                setImgSize(classes.imgBigSize);
                break;
            default:
                setImgSize(classes.imgMediumSize);
                break;
        }
    }, [classes.imgBigSize, classes.imgMediumSize, classes.imgSmallSize, classes.imgGiantSize, widgetProps.image_size]);
    if (!widgetProps.src) {
        return <InsertPhotoIcon color={!isJourney || isReadOnly ? 'disabled' : 'inherit'} />;
    }
    return (
        <div className={`${imgAlignment} ${classes.imageWidgetImgContainer}`}>
            <img
                className={`${imgSize} ${
                    widgetProps.image_size ? 'image-size-' + widgetProps.image_size.toLowerCase() : ''
                }`}
                id={widgetProps.id}
                src={widgetProps.src}
                alt={widgetProps.title}
            />
        </div>
    );
};

ImageWidget.propTypes = {
    classes: PropTypes.object.isRequired,
    widgetProps: PropTypes.object.isRequired
};

export default withStyles(formStyles)(ImageWidget);
