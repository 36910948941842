/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Box, IconButton, List, ListItem, Typography, Divider, Grid, Collapse } from '@material-ui/core';
import useStyles from './styles';
import { Camera, MedicalServices } from '../../../assets/icons';
import Moment from 'moment';
import { TREATMENT_TYPES, TREATMENT_NOTE_TYPES } from '../../../collums-constants';
import { groupBy, sumBy } from 'lodash';
import EditPrescription from '../prescriptions/EditPrescription';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CreateIcon from '@material-ui/icons/Create';
import PrescriptionApi from './../../../api/prescriptionApi';
import LoadingScreen from './../../../collums-components/components/common/loadingScreen';
import HistoryIcon from '@material-ui/icons/History';
import TableChartIcon from '@material-ui/icons/TableChart';
import truncUnitCounter from '../../../services/helpers/truncUnitCounter';
import LaserTreatmentListModal from './TreatmentDesignForm/laserTreatment/LaserTreatmentListModal/LaserTreatmentListModal';
import { default as BasicLaserTreatmentListModal } from './TreatmentDesignForm/basicLaserIPLTreatment/LaserTreatmentListModal/LaserTreatmentListModal';

const MAX_LOAD_ATTEMPTS = 3;
const LOAD_TIMEOUT = 5000;

function TreatmentNotesList({
    classes,
    renderTopDivider,
    customer,
    treatmentNotes,
    setShowTreatments,
    openImage,
    onSelectTreatment,
    openMedicalModals,
    treatmentsHighlighted,
    openTreatmentPhotos,
    practitioner,
    openLog,
    formRef
}) {
    const [selectedPrescription, setSelectedPrescription] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showBasicLaserModal, setShowBasicLaserModal] = useState(false);
    const [currentTreatment, setCurrentTreatment] = useState(false);

    const fetchPrescription = async prescriptionId => {
        try {
            setIsLoading(true);
            const { data } = await PrescriptionApi.getPrescription(prescriptionId);
            setSelectedPrescription(data);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            const images = document.getElementsByClassName('treatment-img');
            for (let i = 0; i < images.length; i++) {
                const img = images[i];
                if (!img.complete || !img.naturalWidth) {
                    const imgParts = img.src.split('?');
                    img.src = imgParts[0] + '?t=' + new Date().getTime();
                }
            }
        }, 5000);
    }, []);

    const TreatmentNote = ({ treatment, isHighlighted }) => {
        // const [imageLoaded, setImageLoaded] = useState(false);
        const [attempt, setAttempt] = useState(1);
        // const [imageError, setImageError] = useState(false);
        const [loadAttempts, setLoadAttempts] = useState(0);
        const [canShowChevron, setCanShowChevron] = useState(true);
        const [isExpanded, setIsExpanded] = useState(false);

        const usedItems = (() => {
            if (treatment.treatmentType === TREATMENT_TYPES.TOXIN) {
                const toxins = (treatment.usedProducts || []).map(el => ({
                    ...el,
                    unit: truncUnitCounter(el.unit, true)
                }));
                const toxinsGroupedByArea = groupBy(toxins, 'area');
                const totalUnits = truncUnitCounter(sumBy(toxins, 'unit'), true);
                const areas = Object.keys(toxinsGroupedByArea).map(name => {
                    const usedUnits = truncUnitCounter(sumBy(toxinsGroupedByArea[name], 'unit'), true);
                    return {
                        usedUnits,
                        name
                    };
                });
                if (!areas.length) return <></>;
                return (
                    <Typography className={classes.defaultFont}>
                        <strong>
                            {areas.reduce(
                                (counter, area) => `${counter}${area.name} ${truncUnitCounter(area.usedUnits)}u. `,
                                ''
                            )}
                            Total {truncUnitCounter(totalUnits)}u
                        </strong>
                    </Typography>
                );
            }
            const fillerProductsText = (treatment.fillers || []).map(
                item => `${item.filler.name?.trim() || ''} x${item.quantity || 1}`
            );
            if (!fillerProductsText.length) return null;

            return (
                <Typography className={classes.defaultFont}>
                    <strong>{fillerProductsText.join(', ')}</strong>
                </Typography>
            );
        })();

        const gridContainerId = `treatment-grid-container-${treatment.id}`;

        useEffect(() => {
            const element = document.getElementById(gridContainerId);
            setCanShowChevron(element.scrollHeight > 200);
        }, [gridContainerId]);

        const imgSrc = () => {
            if (treatment?.lhrCanvasImage) {
                return treatment.lhrCanvasImage + (loadAttempts > 0 ? `?retry=${loadAttempts}` : '');
            }
            if (treatment.canvasImage) {
                return (
                    treatment.canvasImage +
                    (treatment.modifiedAt
                        ? '?t=' + treatment.modifiedAt + (loadAttempts > 0 ? `&retry=${loadAttempts}` : '')
                        : loadAttempts > 0
                        ? `?retry=${loadAttempts}`
                        : '')
                );
            }
            return false;
        };

        const lhrProblem =
            treatment?.lhrRows && treatment?.lhrRows.length && !!treatment?.lhrRows.find(row => row?.problem);

        const handleImageLoad = () => {
            // setImageLoaded(true);
            setAttempt(prev => prev + 1);
            // setImageError(false);
        };

        const handleImageError = () => {
            if (loadAttempts < MAX_LOAD_ATTEMPTS) {
                setTimeout(() => {
                    setLoadAttempts(prev => prev + 1);
                }, LOAD_TIMEOUT);
                // setImageLoaded(false);
                // setImageError(false);
                // } else {
                //     setImageLoaded(false);
                //     setImageError(true);
            }
        };

        // useEffect(() => {
        //     if (!imageLoaded && !imageError && loadAttempts > 0) {
        //         const timeout = setTimeout(() => {
        //             setLoadAttempts(prev => prev + 1);
        //         }, LOAD_TIMEOUT);
        //
        //         return () => clearTimeout(timeout);
        //     }
        // }, [imageLoaded, imageError, loadAttempts]);

        return (
            <>
                <Collapse
                    key={`treatment-${treatment.id}`}
                    id={treatment.id}
                    className={`${classes.treatmentBox}`}
                    in={isExpanded}
                    collapsedSize={210}
                    easing={'height'}
                    timeout={400}
                >
                    <ListItem
                        className={`${classes.treatmentItem} ${
                            isExpanded || isHighlighted ? '' : classes.notExpandedTreatmentItem
                        }`}
                    >
                        <Grid style={{ flexWrap: 'nowrap' }} container direction="row" id={gridContainerId}>
                            <Grid item style={{ flexWrap: 'nowrap' }} container direction="column">
                                <Grid item style={{ flexWrap: 'wrap' }} container direction="row">
                                    <Grid item className={classes.titlesSize}>
                                        <Typography
                                            variant="span"
                                            className={`${classes.marginRight} ${classes.defaultFont} ${classes.bold}`}
                                        >
                                            {Moment(treatment.createdAt).format('DD/MM/YYYY HH:mm')}
                                        </Typography>
                                    </Grid>
                                    {treatment.service?.name && (
                                        <Grid item className={classes.titlesSize}>
                                            <Typography
                                                variant="span"
                                                className={`${classes.marginRight} ${classes.defaultFont} ${classes.bold}`}
                                            >
                                                {treatment.service.name}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item className={classes.titlesSize}>
                                        <Typography
                                            variant="span"
                                            className={`${classes.marginRight} ${classes.defaultFont} ${classes.bold}`}
                                        >
                                            {treatment.practitioner?.displayName}
                                        </Typography>
                                    </Grid>
                                    {(treatment?.changes || lhrProblem) && (
                                        <Grid item className={classes.titlesSize}>
                                            <Typography
                                                variant="span"
                                                onClick={() => openMedicalModals(treatment)}
                                                className={`${classes.marginRight} ${classes.defaultFont} ${classes.changeAlert} ${classes.bold}`}
                                            >
                                                {treatment.changes ? treatment.changes : ''}
                                                {lhrProblem ? ' Problem' : ''}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid>
                                    <div className={`${classes.treatmentNoteContent} ${classes.defaultFont}`}>
                                        {(treatment.notes || []).map(noteItem => (
                                            <div
                                                key={noteItem.id}
                                                dangerouslySetInnerHTML={{
                                                    __html: noteItem.note
                                                }}
                                            />
                                        ))}
                                        {usedItems && (
                                            <Typography className={classes.defaultFont}>{usedItems}</Typography>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid className={classes.imageAndIconsContainer} container direction="row">
                                <Grid item className={classes.imageGrid}>
                                    {treatment.type === TREATMENT_NOTE_TYPES.TREATMENT && (
                                        <div
                                            className={classes.treatmentImageArea}
                                            onClick={() => openImage(treatment)}
                                        >
                                            {Boolean(imgSrc()) && attempt && (
                                                <img
                                                    alt="Treatment"
                                                    className={classes.treatmentNoteImage + ' treatmentImg'}
                                                    src={imgSrc()}
                                                    onLoad={handleImageLoad}
                                                    onError={handleImageError}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Box className={classes.iconBox}>
                                        <IconButton
                                            className={classes.iconButton}
                                            onClick={() => onSelectTreatment(treatment)}
                                        >
                                            <CreateIcon />
                                        </IconButton>
                                        {((treatment.journey && treatment.journey.photo) ||
                                            Boolean(treatment?.photos?.length)) && (
                                            <IconButton
                                                className={classes.iconButton}
                                                onClick={() => {
                                                    openTreatmentPhotos(treatment);
                                                }}
                                            >
                                                <Camera variant="special" />
                                            </IconButton>
                                        )}
                                        {treatment.prescription && (
                                            <IconButton
                                                className={classes.iconButton}
                                                onClick={() => fetchPrescription(treatment.prescription)}
                                            >
                                                <MedicalServices variant="special" />
                                            </IconButton>
                                        )}
                                        <IconButton
                                            className={classes.iconButton}
                                            onClick={() => {
                                                openLog(treatment.id);
                                            }}
                                        >
                                            <HistoryIcon />
                                        </IconButton>
                                        {treatment.treatmentType === TREATMENT_TYPES.LHR && (
                                            <IconButton
                                                className={classes.iconButton}
                                                onClick={() => {
                                                    setCurrentTreatment(treatment);
                                                    setShowModal(true);
                                                }}
                                            >
                                                <TableChartIcon />
                                            </IconButton>
                                        )}
                                        {treatment.treatmentType === TREATMENT_TYPES.BLIPL && (
                                            <IconButton
                                                className={classes.iconButton}
                                                onClick={() => {
                                                    setCurrentTreatment(treatment);
                                                    setShowBasicLaserModal(true);
                                                }}
                                            >
                                                <TableChartIcon />
                                            </IconButton>
                                        )}
                                        <span style={{ color: 'white' }}>{loadAttempts}</span>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                </Collapse>
                {canShowChevron && (
                    <div className={classes.expandButtonArea}>
                        <IconButton
                            className={classes.expandButton}
                            onClick={() => {
                                if (isHighlighted) return;
                                setIsExpanded(!isExpanded);
                            }}
                        >
                            {isExpanded || isHighlighted ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </div>
                )}
                <Divider className={classes.divider} />
            </>
        );
    };

    TreatmentNote.propTypes = {
        treatment: PropTypes.object.isRequired
    };

    return (
        <>
            <LoadingScreen isVisible={isLoading} />
            <List className={classes.listView} id="note-list">
                {renderTopDivider && <Divider className={classes.divider} />}
                {treatmentNotes
                    .filter(treatment => treatment.id !== formRef?.current?.id)
                    .map(treatment => (
                        <TreatmentNote
                            treatment={treatment}
                            key={treatment.id}
                            isHighlighted={treatmentsHighlighted.some(note => note === treatment.id)}
                        />
                    ))}
            </List>

            <LaserTreatmentListModal
                open={showModal}
                onClose={() => setShowModal(false)}
                treatment={currentTreatment}
            />
            <BasicLaserTreatmentListModal
                open={showBasicLaserModal}
                onClose={() => setShowBasicLaserModal(false)}
                treatment={currentTreatment}
            />

            {selectedPrescription && (
                <EditPrescription
                    selectedPrescription={selectedPrescription}
                    setSelectedPrescription={setSelectedPrescription}
                    customer={customer}
                    reload={prescription => {
                        const treatmentList = treatmentNotes.map(el => {
                            if (el.prescription?.id === prescription.id) {
                                return {
                                    ...el,
                                    prescription
                                };
                            }
                            return el;
                        });
                        setShowTreatments(treatmentList);
                    }}
                    practitioner={practitioner}
                />
            )}
        </>
    );
}

TreatmentNotesList.propTypes = {
    classes: PropTypes.object,
    renderTopDivider: PropTypes.bool,
    customer: PropTypes.object.isRequired,
    treatmentNotes: PropTypes.array.isRequired,
    onSelectTreatment: PropTypes.func,
    setShowTreatments: PropTypes.func.isRequired,
    openImage: PropTypes.func.isRequired,
    openMedicalModals: PropTypes.func.isRequired,
    treatmentsHighlighted: PropTypes.array.isRequired,
    openTreatmentPhotos: PropTypes.func.isRequired,
    practitioner: PropTypes.object.isRequired,
    openLog: PropTypes.func.isRequired,
    formRef: PropTypes.object.isRequired
};

export default withStyles(useStyles)(TreatmentNotesList);
