import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Cookies } from 'react-cookie';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { sidebarStyles } from './styles';
import { BroadcastChannel } from 'broadcast-channel';

import {
    Calendar,
    ChevronBackWhite,
    ChevronForwardWhite,
    Help,
    Stock,
    Lock,
    LogOut,
    Marketing,
    // Client,
    Schedule,
    Pos,
    Reports,
    Search,
    Settings,
    ZoomIn,
    ZoomOut,
    EyeOffWhite,
    Eye
} from '../../assets/icons';

import AuthApi from '../../api/AuthApi';
import configApi, { enqueueLoginRedirect, enqueueLogoutRedirect, links } from '../../api/config';
import { clearUserCache, validateJobPermissions } from '../../helpers/index';
import { COLLUMS_APPS_ACCESS_TIER } from '../../../collums-constants';
import { isAvailableByPlan } from '../../../collums-constants/utils';
import { CURRENT_CLINIC } from '../../../collums-constants/storageKeys';
import { useAccessTier } from '../../hooks/accessTier';
import { useCookies } from 'react-cookie';

const bcOptions = { type: 'localstorage', webWorkerSupport: false };
const authBroadcastChannel = new BroadcastChannel('collums-lockscreen', bcOptions);

function Sidebar({
    isAuthenticated = true,
    logout = () => {},
    appName,
    classes,
    showZoom = false,
    currentZoom,
    zoomIn,
    zoomOut,
    showSearch = true,
    showSearchCustomerModal,
    showHideCustomerInfo = false,
    hideCustomerInfo,
    showLock = false,
    showHelp = false,
    ZOOM_LEVEL,
    TOKEN = 'token',
    PIN,
    INFO_HIDDEN,
    setShowPinModal = () => {}
}) {
    const [, setCookie] = useCookies();
    const [infoHidden, setInfoHidden] = useState();
    const [permissions, setPermissions] = useState({ calendar: true });
    const [isLocking, setIsLocking] = useState(false);
    const { openFeatureModal } = useAccessTier();
    const zoomInEnabledRef = useRef(true);
    const zoomOutEnabledRef = useRef(true);
    const [open, setOpen] = useState(false);
    const currentClinic = localStorage.getItem(CURRENT_CLINIC);
    const allPermissions = {
        admin: true,
        calendar: true,
        employees: true,
        marketing: true,
        pos: true,
        stock: true,
        reporting: true
    };

    const triggerFeatureModal = useCallback(() => {
        try {
            openFeatureModal();
        } catch (err) {
            console.error(err);
        }
        //eslint-disable-next-line
    }, []);

    function hoverElementChange(type, elId, labelOrIcon, hoverIn) {
        if (hoverIn) {
            if (labelOrIcon) {
                const elLabel = document.getElementById(`${elId}Label`);
                if (elLabel) elLabel.classList.add(type);
                const elIcon = document.getElementById(`${elId}Icon`);
                if (elIcon) elIcon.classList.add(type);
            } else {
                const el = document.getElementById(elId);
                if (el) el.classList.add(type);
            }
        } else {
            if (labelOrIcon) {
                const elLabel = document.getElementById(`${elId}Label`);
                if (elLabel) elLabel.classList.remove(type);
                const elIcon = document.getElementById(`${elId}Icon`);
                if (elIcon) elIcon.classList.remove(type);
            } else {
                const el = document.getElementById(elId);
                if (el) el.classList.remove(type);
            }
        }
    }

    function hoverContainerChange(contId, hoverIn) {
        const el = document.getElementById(contId);
        if (el) {
            if (hoverIn) el.classList.add('visible');
            else el.classList.remove('visible');
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        configApi.resetToken(token);
        AuthApi.getMe()
            .then(async me => {
                const permissions = me.role === 'admin' ? allPermissions : me.job?.permissions;
                setPermissions(permissions);
                if (token) {
                    try {
                        await validateJobPermissions(token, me, process.env.REACT_APP_NAME);
                        localStorage.setItem('token', token);
                    } catch (error) {
                        localStorage.removeItem('token');
                        if (error && error.status === 401) {
                            enqueueLoginRedirect();
                        } else {
                            console.error(error);
                        }
                    }
                } else {
                    enqueueLoginRedirect();
                }
            })
            .catch(err => {
                localStorage.removeItem('token');
                if (err?.status === 401) {
                    enqueueLoginRedirect();
                }
            });
    }, []); //eslint-disable-line

    useEffect(() => {
        if (showHideCustomerInfo) {
            const cookie = new Cookies();
            let isInfoHidden = cookie.get(INFO_HIDDEN);
            setInfoHidden(isInfoHidden === 'true');
            hideCustomerInfo(isInfoHidden === 'true');
        }
    }, [showHideCustomerInfo, hideCustomerInfo, INFO_HIDDEN]);

    useEffect(() => {
        localStorage.setItem(ZOOM_LEVEL, currentZoom);
    }, [zoomIn, zoomOut, currentZoom, ZOOM_LEVEL]);

    useEffect(() => {
        if (showHideCustomerInfo) {
            const cookies = new Cookies();
            cookies.set(INFO_HIDDEN, infoHidden ? true : false, { path: '/' });
            hideCustomerInfo(infoHidden);
        }
    }, [infoHidden, hideCustomerInfo, showHideCustomerInfo, INFO_HIDDEN]);

    const handleRedirectApp = useCallback(
        (event, appTier) => {
            if (event && appTier && !isAvailableByPlan(appTier)) {
                event.preventDefault();
                event.stopPropagation();
                triggerFeatureModal();
                return true;
            }
            return false;
        },
        [triggerFeatureModal]
    );

    const redirectTo = useCallback(
        (appUrl, appTier, event) => {
            if (handleRedirectApp(event, appTier)) {
                return;
            }
            window.location = `${appUrl}?token=${localStorage.getItem(TOKEN)}&clinic=${currentClinic}`;
        },
        [TOKEN, handleRedirectApp, currentClinic]
    );

    const redirectToSearch = useCallback(
        (appUrl, appTier, event) => {
            if (handleRedirectApp(event, appTier)) {
                return;
            }
            const url = `${appUrl}?token=${localStorage.getItem(TOKEN)}&withSearch=1`;
            window.location = url;
        },
        [TOKEN, handleRedirectApp]
    );

    // eslint-disable-next-line no-unused-vars
    const handleLockScreen = useCallback(async (isLocking, event) => {
        if (isLocking) return;

        setIsLocking(true);
        setShowPinModal(true);

        const environment = {};
        if (
            window.location.hostname !== 'localhost' &&
            window.location.hostname !== 'devcollumsapi.net' &&
            window.location.hostname !== '192.168.15.204'
        ) {
            environment.path = '/';
            environment.domain = '.collums.co.uk';
        }

        setCookie('isLocked', true, environment);
        authBroadcastChannel.postMessage(JSON.stringify({ token: '' }));

        if (showLock) {
            setCookie(PIN, undefined, { path: '/' });
        }

        // await AuthApi.removeToken();
        hoverContainerChange('sidebarContainer', false);
        logout();
        localStorage.removeItem(TOKEN);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogout = useCallback(async () => {
        clearUserCache();
        if (showLock) {
            const cookie = new Cookies();
            cookie.set(PIN, undefined, { path: '/' });
        }

        const res = await AuthApi.removeToken();

        if (res) {
            hoverContainerChange('sidebarContainer', false);
            logout();
            localStorage.removeItem(TOKEN);
            enqueueLogoutRedirect();
        }
    }, [PIN, TOKEN, logout, showLock]);

    const renderMobileIcon = useMemo(() => {
        const isTouchDevice = 'ontouchstart' in document.documentElement;
        if (!isTouchDevice) {
            return;
        }
        return (
            <div
                id={'renderMobileIcon'}
                className={classes.mobileContainer}
                onClick={() => {
                    hoverContainerChange('sidebarContainer', !open);
                    setOpen(!open);
                }}
                onMouseEnter={() => hoverElementChange('mobile', 'renderMobileIcon', true)}
                onMouseLeave={() => hoverElementChange('mobile', 'renderMobileIcon', false)}
            >
                {open ? <ChevronForwardWhite /> : <ChevronBackWhite />}
            </div>
        );
    }, [classes.mobileContainer, open]);

    const renderMobileLabel = useMemo(() => {
        const isTouchDevice = 'ontouchstart' in document.documentElement;
        if (!isTouchDevice) {
            return;
        }
        return (
            <div
                id={'renderMobileLabel'}
                className={classes.mobileContainer}
                onClick={() => {
                    hoverContainerChange('sidebarContainer', !open);
                    setOpen(!open);
                }}
                onMouseEnter={() => hoverElementChange('mobile', 'renderMobileLabel', true)}
                onMouseLeave={() => hoverElementChange('mobile', 'renderMobileLabel', false)}
            />
        );
    }, [classes.mobileContainer, open]);

    const renderSidebarItem = useCallback(
        (itemName, Item, enabled, action, url, appTier) => {
            const itemType = typeof Item === 'string' ? 'Label' : 'Icon';

            if (permissions) {
                let app = itemName;

                if (itemName === 'schedules') {
                    app = 'employees';
                }
                if (itemName === 'reports') {
                    app = 'reporting';
                }

                if (app in permissions) {
                    if (!permissions[app]) return null;
                }
            }

            let classString = `${itemName} ${itemType === 'Label' ? classes.label : classes.icon}`;
            if (!enabled) {
                classString += ' disabled';
                const el = document.getElementById(`${itemName}${itemType}`);
                if (el) el.classList.add('disabled');
            } else {
                const el = document.getElementById(`${itemName}${itemType}`);
                if (el) el.classList.remove('disabled');
            }

            if (appName === itemName) {
                classString += ' active';
                const el = document.getElementById(`${itemName}${itemType}`);
                if (el) el.classList.add('active');
            } else {
                const el = document.getElementById(`${itemName}${itemType}`);
                if (el) el.classList.remove('active');
            }

            return (
                <a
                    href={url}
                    id={`${itemName}${itemType}`}
                    className={classString}
                    onMouseEnter={() => hoverElementChange('hover', itemName, true, true)}
                    onMouseLeave={() => hoverElementChange('hover', itemName, true, false)}
                    onClick={event => {
                        if (handleRedirectApp(event, appTier)) {
                            return;
                        }
                        if (action) action(event);
                    }}
                >
                    {itemType === 'Label' ? Item : <Item />}
                </a>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [permissions, appName, handleRedirectApp, currentZoom]
    );

    const renderZoom = useMemo(() => {
        if (!showZoom) {
            return <div />;
        }
        zoomInEnabledRef.current = currentZoom !== 12;
        zoomOutEnabledRef.current = currentZoom !== 2;
        return (
            <>
                {renderSidebarItem('zoomIn', ZoomIn, zoomInEnabledRef.current, () => {
                    zoomInEnabledRef.current && zoomIn();
                })}
                {renderSidebarItem('zoomOut', ZoomOut, zoomOutEnabledRef.current, () => {
                    zoomOutEnabledRef.current && zoomOut();
                })}
            </>
        );
    }, [currentZoom, renderSidebarItem, showZoom, zoomIn, zoomOut]);

    const renderZoomLabels = useMemo(() => {
        if (!showZoom) {
            return <div />;
        }
        zoomInEnabledRef.current = currentZoom !== 12;
        zoomOutEnabledRef.current = currentZoom !== 2;
        return (
            <>
                {renderSidebarItem('zoomIn', 'Zoom in', zoomInEnabledRef.current, async () => {
                    zoomInEnabledRef.current && zoomIn();
                })}
                {renderSidebarItem('zoomOut', 'Zoom out', zoomOutEnabledRef.current, async () => {
                    zoomOutEnabledRef.current && zoomOut();
                })}
            </>
        );
    }, [currentZoom, renderSidebarItem, showZoom, zoomIn, zoomOut]);

    const renderHideInfo = useMemo(() => {
        if (showHideCustomerInfo) {
            if (!infoHidden)
                return () =>
                    renderSidebarItem('hideInfo', Eye, true, () => {
                        setInfoHidden(true);
                    });
            return () =>
                renderSidebarItem('showInfo', EyeOffWhite, true, () => {
                    setInfoHidden(false);
                });
        } else {
            return () => {};
        }
    }, [infoHidden, renderSidebarItem, showHideCustomerInfo]);

    const renderHideInfoLabels = useMemo(() => {
        if (showHideCustomerInfo) {
            if (!infoHidden)
                return () =>
                    renderSidebarItem('hideInfo', 'Hide info', true, () => {
                        setInfoHidden(true);
                    });
            return () =>
                renderSidebarItem('showInfo', 'Show info', true, () => {
                    setInfoHidden(false);
                });
        } else {
            return () => {};
        }
    }, [infoHidden, renderSidebarItem, showHideCustomerInfo]);

    const content = useMemo(
        () => (
            <>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" />
                <div className={classes.containerWrapper}>
                    <div
                        id={'sidebarContainer'}
                        className={`${classes.container}`}
                        onMouseEnter={() => {
                            hoverContainerChange('sidebarContainer', true);
                        }}
                        onMouseLeave={() => {
                            hoverContainerChange('sidebarContainer', false);
                        }}
                    >
                        <div className={classes.labelsContainer}>
                            <div>
                                {renderMobileLabel}
                                {showSearch &&
                                    renderSidebarItem(
                                        'search',
                                        'Search',
                                        true,
                                        process.env.REACT_APP_NAME === 'calendar'
                                            ? showSearchCustomerModal
                                            : e =>
                                                  redirectToSearch(
                                                      `${links.calendarUrl}?clinic=${currentClinic}`,
                                                      COLLUMS_APPS_ACCESS_TIER.CALENDAR,
                                                      e
                                                  ),
                                        process.env.REACT_APP_NAME === 'calendar'
                                            ? null
                                            : links.calendarUrl +
                                                  `?token=${localStorage.getItem(TOKEN)}&withSearch=true`,
                                        COLLUMS_APPS_ACCESS_TIER.CALENDAR
                                    )}
                                {renderSidebarItem(
                                    'calendar',
                                    'Calendar',
                                    true,
                                    e => redirectTo(links.calendarUrl, COLLUMS_APPS_ACCESS_TIER.CALENDAR, e),
                                    `${links.calendarUrl}?clinic=${currentClinic}`,
                                    COLLUMS_APPS_ACCESS_TIER.CALENDAR
                                )}
                                {/*renderSidebarItem('clients', 'Clients', true, () => {})*/}
                                {renderSidebarItem(
                                    'pos',
                                    'POS',
                                    true,
                                    e => redirectTo(links.posUrl, COLLUMS_APPS_ACCESS_TIER.POS, e),
                                    `${links.posUrl}?clinic=${currentClinic}`
                                )}
                                {renderSidebarItem(
                                    'marketing',
                                    'Marketing',
                                    true,
                                    e => redirectTo(links.marketingUrl, COLLUMS_APPS_ACCESS_TIER.MARKETING, e),
                                    `${links.marketingUrl}?clinic=${currentClinic}`,
                                    COLLUMS_APPS_ACCESS_TIER.MARKETING
                                )}
                                {renderSidebarItem(
                                    'reports',
                                    'Reports',
                                    true,
                                    e => redirectTo(links.reportingUrl, COLLUMS_APPS_ACCESS_TIER.REPORTING, e),
                                    `${links.reportingUrl}?clinic=${currentClinic}`,
                                    COLLUMS_APPS_ACCESS_TIER.REPORTING
                                )}
                                {renderSidebarItem(
                                    'stock',
                                    'Stock',
                                    true,
                                    e => redirectTo(links.stockUrl, COLLUMS_APPS_ACCESS_TIER.STOCK, e),
                                    `${links.stockUrl}?clinic=${currentClinic}`,
                                    COLLUMS_APPS_ACCESS_TIER.STOCK
                                )}
                                {renderSidebarItem(
                                    'schedules',
                                    'Schedules',
                                    true,
                                    e => redirectTo(links.employeesUrl, COLLUMS_APPS_ACCESS_TIER.SCHEDULES, e),
                                    `${links.employeesUrl}?clinic=${currentClinic}`,
                                    COLLUMS_APPS_ACCESS_TIER.SCHEDULES
                                )}
                                {renderSidebarItem(
                                    'admin',
                                    'Admin',
                                    true,
                                    e => redirectTo(links.adminUrl, COLLUMS_APPS_ACCESS_TIER.ADMIN, e),
                                    `${links.adminUrl}?clinic=${currentClinic}`,
                                    COLLUMS_APPS_ACCESS_TIER.ADMIN
                                )}
                                {renderSidebarItem('lock', 'Lock screen', true, e => handleLockScreen(isLocking, e))}
                                {showHelp && renderSidebarItem('help', 'Help', true, () => {})}
                            </div>
                            <div>
                                {renderHideInfoLabels()}
                                {renderZoomLabels}
                                {renderSidebarItem('logout', 'Log out', true, handleLogout)}
                            </div>
                        </div>
                        <div className={classes.iconsContainer}>
                            <div>
                                {renderMobileIcon}
                                {showSearch &&
                                    renderSidebarItem(
                                        'search',
                                        Search,
                                        true,
                                        process.env.REACT_APP_NAME === 'calendar'
                                            ? showSearchCustomerModal
                                            : e =>
                                                  redirectToSearch(
                                                      `${links.calendarUrl}?clinic=${currentClinic}`,
                                                      COLLUMS_APPS_ACCESS_TIER.CALENDAR,
                                                      e
                                                  ),
                                        process.env.REACT_APP_NAME === 'calendar'
                                            ? null
                                            : links.calendarUrl +
                                                  `?token=${localStorage.getItem(TOKEN)}&withSearch=true`
                                    )}
                                {renderSidebarItem(
                                    'calendar',
                                    Calendar,
                                    true,
                                    e => redirectTo(links.calendarUrl, COLLUMS_APPS_ACCESS_TIER.CALENDAR, e),
                                    `${links.calendarUrl}?clinic=${currentClinic}`
                                )}
                                {/*renderSidebarItem('clients', Client, true, () => {})*/}
                                {renderSidebarItem(
                                    'pos',
                                    Pos,
                                    true,
                                    e => redirectTo(links.posUrl, COLLUMS_APPS_ACCESS_TIER.POS, e),
                                    `${links.posUrl}?clinic=${currentClinic}`
                                )}
                                {renderSidebarItem(
                                    'marketing',
                                    Marketing,
                                    true,
                                    e => redirectTo(links.marketingUrl, COLLUMS_APPS_ACCESS_TIER.MARKETING, e),
                                    `${links.marketingUrl}?clinic=${currentClinic}`
                                )}
                                {renderSidebarItem(
                                    'reports',
                                    Reports,
                                    true,
                                    e => redirectTo(links.reportingUrl, COLLUMS_APPS_ACCESS_TIER.REPORTING, e),
                                    `${links.reportingUrl}?clinic=${currentClinic}`
                                )}
                                {renderSidebarItem(
                                    'stock',
                                    Stock,
                                    true,
                                    e => redirectTo(links.stockUrl, COLLUMS_APPS_ACCESS_TIER.STOCK, e),
                                    `${links.stockUrl}?clinic=${currentClinic}`
                                )}
                                {renderSidebarItem(
                                    'schedules',
                                    Schedule,
                                    true,
                                    e => redirectTo(links.employeesUrl, COLLUMS_APPS_ACCESS_TIER.SCHEDULES, e),
                                    `${links.employeesUrl}?clinic=${currentClinic}`
                                )}
                                {renderSidebarItem(
                                    'admin',
                                    Settings,
                                    true,
                                    e => redirectTo(links.adminUrl, COLLUMS_APPS_ACCESS_TIER.ADMIN, e),
                                    `${links.adminUrl}?clinic=${currentClinic}`
                                )}
                                {renderSidebarItem('lock', Lock, true, e => handleLockScreen(isLocking, e))}
                                {showHelp && renderSidebarItem('help', Help, true, () => {})}
                            </div>
                            <div>
                                {renderHideInfo()}
                                {renderZoom}
                                {renderSidebarItem('logout', LogOut, true, handleLogout)}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [infoHidden, showZoom, open, currentClinic, permissions, isLocking]
    );

    if (!isAuthenticated) {
        return null;
    }

    return content;
}

Sidebar.propTypes = {
    isAuthenticated: PropTypes.bool,
    currentZoom: PropTypes.number,
    logout: PropTypes.func,
    zoomIn: PropTypes.func,
    zoomOut: PropTypes.func,
    showSearchCustomerModal: PropTypes.func,
    appName: PropTypes.string.isRequired,
    showZoom: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    hideCustomerInfo: PropTypes.func,
    showLock: PropTypes.bool,
    showHideCustomerInfo: PropTypes.bool,
    showSearch: PropTypes.bool,
    CreatePIN: PropTypes.func,
    LookScreenModal: PropTypes.func,
    showHelp: PropTypes.bool,
    ZOOM_LEVEL: PropTypes.string,
    TOKEN: PropTypes.string,
    PIN: PropTypes.string,
    SCREENLOCKED: PropTypes.string,
    INFO_HIDDEN: PropTypes.string,
    setShowPinModal: PropTypes.func
};

export default withStyles(sidebarStyles)(Sidebar);
