import { useEffect, useState } from 'react';
import authApi from '../api/AuthApi';
import jobApi from '../api/jobApi';
import { enqueueLogoutRedirect } from '../api/config';
import {
    CURRENCY_TYPES,
    ROLES,
    DEFAULT_PHONE_COUNTRY,
    COUNTRY_CODES,
    COUNTRY_PHONE_CODES,
    DEFAULT_PHONE_COUNTRY_CODE
} from '../../collums-constants';

export const getServiceRealPrice = ({ service, clinicId, staffId }) => {
    if (!service.locations?.length) return { grossPrice: service.grossPrice, netPrice: service.netPrice };
    const clinic = service.locations.find(location => location.clinic === clinicId);
    if (!clinic || !clinic.grossPrice || !clinic.netPrice)
        return { grossPrice: service.grossPrice, netPrice: service.netPrice };

    const staff = clinic.staffs.find(staff => staff.id === staffId);
    if (!staff || !staff.grossPrice || !staff.netPrice)
        return { grossPrice: clinic.grossPrice, netPrice: clinic.netPrice };

    return { grossPrice: staff.grossPrice, staff: service.netPrice };
};

export const clearUserCache = () => {
    const dbName = 'CollumsCache';
    const tableName = 'schedules';

    const request = indexedDB.open(dbName);

    request.onsuccess = function(event) {
        const db = event.target.result;
        const transaction = db.transaction([tableName], 'readwrite');
        const objectStore = transaction.objectStore(tableName);

        const clearRequest = objectStore.clear();

        clearRequest.onsuccess = function() {
            // eslint-disable-next-line no-console
            console.log('Cache removed.');
        };

        clearRequest.onerror = function(event) {
            // eslint-disable-next-line no-console
            console.error('Cache removing error', event.target.error);
        };
    };

    request.onerror = function(event) {
        // eslint-disable-next-line no-console
        console.error('Cache db connection error', event.target.error);
    };
};

export const toLocaleString = value => {
    if (value === undefined || isNaN(Number(value))) return '';
    const currency = CURRENCY_TYPES.find(el => el.NAME === localStorage.getItem('currency'));
    if (currency) {
        const localeStr = Number(value).toLocaleString(currency.LANGUAGE, {
            style: 'currency',
            currency: currency.NAME,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        return localeStr;
    }
    const localeStr = Number(value).toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return localeStr;
};

export const isEmptyString = value => {
    return !(value || '').trim();
};

export const getCurrencySymbol = () => {
    const currency = localStorage.getItem('currency');
    if (currency) {
        const orgCurrencySymbol = CURRENCY_TYPES.find(type => type.NAME === currency);
        if (orgCurrencySymbol) {
            return orgCurrencySymbol.SYMBOL;
        }
        return '£';
    }
    return '£';
};

export const isValidEmail = email => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
};

export const convertLocaleString = value => {
    if (!value) return 0;
    if (typeof value !== 'string') return value;
    const position = value[0] === '-' ? 1 : 0;
    let text = value;
    while (text.includes(',')) {
        text = text.replace(',', '');
    }
    const convertedValue = Number(text.split(value[position]).join(''));
    return convertedValue;
};

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        },
        // eslint-disable-next-line
        [value]
    );

    return debouncedValue;
};

export const swapPosition = (array, index, newIndex) => {
    [array[index], array[newIndex]] = [array[newIndex], array[index]];
    return array;
};
/**
 * ## Swaps indexes on array
 *
 * **E.g:**
 *
 * **array = ['a', 'b', 'c', 'd'];**
 *
 * **permutation = [1,2,3]**
 *
 * ### returns ['a' ,'c', 'd', 'b']
 *
 * #### Read the permutation as *"go from index*, *to*, *then to*, *then to*, *then to"*...
 *
 */
export const permutate = (array, permutation = []) => {
    const _array = [...array];
    function swap(a, b) {
        [_array[a], _array[b]] = [_array[b], _array[a]];
    }
    permutation.forEach((permute, index) => {
        const nextPermute = permutation[index + 1];
        if (nextPermute === undefined) return;
        swap(permute, nextPermute);
    });
    return _array;
};
export const changeFileProp = (file, prop, value) => {
    Object.defineProperty(file, prop, {
        writable: true,
        value
    });
};
export const hasErrors = (id, errors) => {
    return (errors || []).some(error => error.id === id);
};

export const getLogoWidth = (imageUrl, height) => {
    return new Promise(resolve => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = loadedImg => {
            if (!loadedImg?.path) return;
            const scale = height / loadedImg.path[0].height;
            const width = loadedImg.path[0].width * scale;
            resolve(width);
        };
        img.onerror = () => {
            resolve(100);
        };
    });
};

/**
 *
 * Solution for some browsers which do not support onload.
 *
 * @param imageUrl
 * @param height
 * @returns {Promise<unknown>}
 */
export const getLogoWidthAlternative = (imageUrl, height) => {
    return new Promise(resolve => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = function() {
            if (!this?.width || !this?.height) return;
            const scale = height / this.height;
            const width = this.width * scale;
            resolve(width);
        };
        img.onerror = () => {
            resolve(100);
        };
    });
};

export const validateJobPermissions = async (token, userDetails, appName) => {
    if (token !== localStorage.getItem('token')) {
        localStorage.setItem('token', token);
    }

    if (userDetails?.role === ROLES.ADMIN) {
        return;
    }

    const jobs = await jobApi.listAll();

    if (!jobs.length) return;
    if (appName === 'journey' || appName === 'patient-journey') {
        if (!userDetails.job?.permissions?.['calendar']) {
            const res = await authApi.removeToken();
            if (res) {
                localStorage.removeItem('token');
                enqueueLogoutRedirect();
            }
        }
    } else if (!userDetails.job?.permissions?.[appName]) {
        const res = await authApi.removeToken();

        if (res) {
            localStorage.removeItem('token');
            enqueueLogoutRedirect();
        }
    }
};

/**
 * Function do not round value. It cut string with fixed number of places.
 *      - Example: 2.4553 -> 2.45
 *
 * @param num
 * @param fixed
 * @returns {number}
 */
export function cutNumericValue(num, fixed) {
    const separator = num.toString().includes('.') ? '.' : ',';

    // Fix incorrect numeric types. Prevent errors.
    num = parseFloat(num);

    const re = new RegExp('^-?\\d+(?:' + separator + '\\d{0,' + (fixed || -1) + '})?');
    return parseFloat(num.toString().match(re)[0]);
}

export const getOrganisationCountryCode = organisation => {
    const orgCountry = organisation?.address?.country || null;
    return Object.prototype.hasOwnProperty.call(COUNTRY_CODES, orgCountry)
        ? COUNTRY_CODES[orgCountry]
        : DEFAULT_PHONE_COUNTRY;
};

export const getOrganisationPhoneCode = organisation => {
    const orgCountry = organisation?.address?.country || null;
    return Object.prototype.hasOwnProperty.call(COUNTRY_PHONE_CODES, orgCountry)
        ? COUNTRY_PHONE_CODES[orgCountry]
        : DEFAULT_PHONE_COUNTRY_CODE;
};
