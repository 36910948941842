import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
const CancelContinueModal = ({
    open = true,
    setOpen,
    onContinue,
    onCancel,
    title,
    content = null,
    contentHtml = null,
    contentText = '',
    contentWidth = 'unset',
    contentMaxWidth = '470px',
    continueButtonText = 'Continue',
    cancelButtonText = 'Cancel',
    closeOnBlur = true,
    disableButtons = false,
    alignItems = 'center',
    showXonTop = false,
    dialogSize = 'md',
    titleFontSize = '16px',
    continueOnClickOutside = false
}) => {
    const cancelAction = () => {
        onCancel ? onCancel() : setOpen(false);
    };

    const confirmAction = () => {
        onContinue();
        setOpen && setOpen(false);
    };

    return (
        <Dialog
            maxWidth={dialogSize}
            open={open}
            onClose={continueOnClickOutside ? () => confirmAction() : () => cancelAction()}
            aria-labelledby="cancel-confirm-modal"
            disableBackdropClick={!closeOnBlur}
        >
            <Title id="cancel-confirm-modal-title" fontSize={titleFontSize}>
                {title}
                {showXonTop && (
                    <IconButton onClick={cancelAction}>
                        <CloseIcon />
                    </IconButton>
                )}
            </Title>
            <Content contentMaxWidth={contentMaxWidth} contentWidth={contentWidth} alignItems={alignItems}>
                {content ? content : <Typography>{contentText || ''}</Typography>}
                {contentHtml ? <div dangerouslySetInnerHTML={{ __html: contentHtml }}></div> : ''}
            </Content>
            <ActionsContainer>
                <Button disabled={disableButtons} onClick={() => cancelAction()}>
                    {cancelButtonText}
                </Button>
                <Button
                    disabled={disableButtons}
                    id="confirm-leave-button"
                    data-cy="confirm-leave-button"
                    onClick={() => confirmAction()}
                >
                    {continueButtonText}
                </Button>
            </ActionsContainer>
        </Dialog>
    );
};
CancelContinueModal.propTypes = {
    contentWidth: PropTypes.string,
    contentMaxWidth: PropTypes.string,
    setOpen: PropTypes.func,
    onContinue: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    contentText: PropTypes.string,
    contentHtml: PropTypes.string,
    content: PropTypes.elementType,
    open: PropTypes.bool,
    continueButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    closeOnBlur: PropTypes.bool,
    continueOnClickOutside: PropTypes.bool,
    disableButtons: PropTypes.bool,
    alignItems: PropTypes.string,
    showXonTop: PropTypes.bool,
    dialogSize: PropTypes.string,
    titleFontSize: PropTypes.string
};
const Title = styled(DialogTitle)`
    h2 {
        font-size: ${props => props.fontSize || '16px'};
        font-weight: 500;
        text-align: center;
        font-family: Roboto, Arial, 'sans-serif';

        & > button {
            position: absolute;
            right: 0;
            top: 0;
            padding: 6px;
            margin: 4px;
        }
    }
`;

const Content = styled(DialogContent)`
    display: flex;
    justify-content: center;
    text-align: center;
    width: ${props => props.contentWidth};
    max-width: ${props => props.contentMaxWidth};
    align-items: ${props => props.alignItems};
    overflow-y: auto;
    p {
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
    }
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 2px;
    }
`;

const ActionsContainer = styled(DialogActions)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem 3rem;
    & button {
        min-width: 80px;
        height: 38px;
        border-radius: 0;
        margin-bottom: 16px;
        text-transform: none;
        font-size: 14px;
        font-family: Roboto, Arial, 'sans-serif';
        padding: 6px 16px;
        border: 1px solid #000;
        font-weight: 500;
    }

    & button:nth-child(2):not(:disabled) {
        color: white;
    }
    & button:nth-child(2) {
        background-color: #b41778;
        border: 0 solid #000;

        &:hover {
            background-color: #912d6b;
        }
    }
`;
export default CancelContinueModal;
