import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Controller, useForm } from 'react-hook-form';
import { styles } from './styles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    withStyles,
    makeStyles,
    IconButton,
    Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import Button from '@material-ui/core/Button';
import PhoneNumberInput from 'material-ui-phone-number';
import { PREFERRED_PHONE_COUNTRIES, RELATIONSHIP_TYPE } from '../../../collums-constants';
import { getOrganisationCountryCode } from '../../../collums-components/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { updateKinOfEmergencySuccess } from '../../../actions/customerActions';
import KinOfEmergencyApi from '../../../api/kinOfEmergencyApi';
import { Autocomplete } from '@material-ui/lab';

const KinOfEmergencyModal = ({ open, onClose, classes, customer, kin = {}, afterSave }) => {
    const globalStyles = makeStyles(modalsButtonStyles)();
    const organisation = useSelector(state => state.calendar.organisation);
    const dispatch = useDispatch();

    const { handleSubmit, errors, control, reset } = useForm({
        defaultValues: {
            name: '',
            address: '',
            phone: '',
            phone2: '',
            relationship: '',
            ...kin
        }
    });

    useEffect(() => {
        reset(kin);
    }, [kin, reset]);

    const saveHandler = useCallback(
        async value => {
            if (Object.keys(errors).length) return;

            try {
                onClose();
                const responseData = await KinOfEmergencyApi.update(customer.id, value);
                dispatch(updateKinOfEmergencySuccess(responseData));
            } finally {
                if (afterSave) {
                    afterSave();
                }
            }
        },
        [afterSave, customer.id, dispatch, errors, onClose]
    );

    const getCountryCode = () => {
        return getOrganisationCountryCode(organisation);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle className={classes.formDialogTitle}>
                <Typography className={`${classes.bold} ${classes.formDialogTitleLabel}`}>Insurance Details</Typography>
                <IconButton aria-label="close" onClick={onClose} className={classes.formDialogCloseButton}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.formDialogContent}>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(saveHandler)();
                    }}
                >
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12}>
                            <FormControl fullWidth={true}>
                                <Controller
                                    name={'name'}
                                    control={control}
                                    render={({ value, onChange }) => (
                                        <TextField
                                            name="name"
                                            variant="outlined"
                                            label="Name"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                ></Controller>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true}>
                                <Controller
                                    name={'address'}
                                    control={control}
                                    render={({ value, onChange }) => (
                                        <TextField
                                            name="address"
                                            variant="outlined"
                                            label="Address"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                ></Controller>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true}>
                                <Controller
                                    name={'phone'}
                                    control={control}
                                    render={({ value, onChange }) => (
                                        <PhoneNumberInput
                                            required
                                            variant="outlined"
                                            value={value}
                                            preferredCountries={PREFERRED_PHONE_COUNTRIES}
                                            defaultCountry={getCountryCode()}
                                            onChange={onChange}
                                        />
                                    )}
                                ></Controller>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true}>
                                <Controller
                                    name={'phone2'}
                                    control={control}
                                    render={({ value, onChange }) => (
                                        <PhoneNumberInput
                                            required
                                            variant="outlined"
                                            value={value}
                                            preferredCountries={PREFERRED_PHONE_COUNTRIES}
                                            defaultCountry={getCountryCode()}
                                            onChange={onChange}
                                        />
                                    )}
                                ></Controller>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true}>
                                <Controller
                                    name={'relationship'}
                                    control={control}
                                    render={({ value, onChange }) => (
                                        <Autocomplete
                                            ListboxProps={{ id: 'listbox' }}
                                            getOptionLabel={option => option}
                                            options={Object.values(RELATIONSHIP_TYPE)}
                                            autoHighlight
                                            value={value}
                                            onChange={(event, newValue) => {
                                                onChange(newValue);
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    tabIndex={3}
                                                    {...params}
                                                    margin="dense"
                                                    label="Relationship"
                                                    variant="outlined"
                                                    className={`${classes.autocomplete} ${classes.font}`}
                                                />
                                            )}
                                        />
                                    )}
                                ></Controller>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className={`${globalStyles.buttonsContainer}`}>
                        <Button className={`${globalStyles.cancelButton} ${classes.cancelButton}`} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={`${globalStyles.confirmButton} ${classes.confirmButton}`} type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

KinOfEmergencyModal.propTypes = {
    open: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    afterSave: PropTypes.func,
    customer: PropTypes.object.isRequired,
    classes: PropTypes.array,
    kin: PropTypes.object
};

export default withStyles(styles)(KinOfEmergencyModal);
