import PropTypes from 'prop-types';
import CloseBtn from '../../../common/CloseBtn';
import React from 'react';
import { Modal, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MembershipApi from '../../../../api/mambershipApi';
import { toastr } from 'react-redux-toastr';
import { setListShouldUpdate } from '../../../../actions/invoiceActions';
import { useDispatch } from 'react-redux';
import { viewModalStyles } from '../../styles';

const ChangeDirectDebitStatus = ({
    classes,
    showChangeDirectDebitStatusModal,
    setShowChangeDirectDebitStatusModal
}) => {
    const dispatch = useDispatch();

    return (
        <Modal open={showChangeDirectDebitStatusModal} className={classes.modal}>
            <div className={classes.root} style={{ width: 700 }}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={() => setShowChangeDirectDebitStatusModal(null)} />
                </div>
                <div className={classes.header}>
                    <p>Are you sure you want to cancel this Direct Debit / Membership?</p>
                </div>
                <div className={classes.actions} style={{ width: '100%', margin: '20px auto' }}>
                    <Button
                        className={classes.cancelButton}
                        key="cancel"
                        variant="outlined"
                        onClick={() => setShowChangeDirectDebitStatusModal(null)}
                    >
                        Back
                    </Button>

                    <Button
                        className={classes.confirmButton}
                        onClick={async () => {
                            const response = await MembershipApi.voidDirectDebit({
                                ...showChangeDirectDebitStatusModal
                            });
                            if (!response.success) {
                                toastr.error(response.message);
                            } else {
                                setTimeout(() => {
                                    toastr.success('Direct Debit was cancelled');
                                    setShowChangeDirectDebitStatusModal(null);
                                    dispatch(setListShouldUpdate(true));
                                }, 500);
                            }
                        }}
                    >
                        Cancel Direct Debit
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

ChangeDirectDebitStatus.propTypes = {
    classes: PropTypes.object.isRequired,
    setShowChangeDirectDebitStatusModal: PropTypes.bool.isRequired,
    showChangeDirectDebitStatusModal: PropTypes.bool.isRequired
};

export default withStyles(viewModalStyles)(ChangeDirectDebitStatus);
