import React, { useEffect, useCallback, useState } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { styles } from './styles';
import KinOfEmergencyApi from '../../../api/kinOfEmergencyApi';
import { toastr } from 'react-redux-toastr';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import KinOfEmergencyModal from './KinOfEmergencyModal';

const KinOfEmergency = ({ customer, classes }) => {
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [kin, setKin] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const setKinOfEmergencyDetails = useCallback(async () => {
        setIsLoading(true);

        try {
            const kinOfEmergency = await KinOfEmergencyApi.getForCustomer(customer.id);
            if (kinOfEmergency?.length) {
                setKin(kinOfEmergency[0]);
            }
        } catch (e) {
            toastr.error('Could not load customer kin of emergency data');
        } finally {
            setIsLoading(false);
        }
    }, [customer.id]);

    useEffect(() => {
        setKinOfEmergencyDetails();
    }, [customer.id, setKinOfEmergencyDetails]);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Grid container className={classes.infoCard} spacing={1}>
                <Grid item container direction="row">
                    <Grid item xs={12} justifyContent="center" alignContent="center">
                        <Typography align="center" variant="h4" className={classes.bold}>
                            Kin Of Emergency Details{' '}
                            <Edit
                                onClick={() => {
                                    setIsFormModalOpen(true);
                                }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Name:
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{kin?.name || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Address
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{kin?.address || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Phone
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{kin?.phone || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        2nd phone
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{kin?.phone2 || ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" className={classes.font14}>
                    <Grid item xs={6}>
                        Relationship
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 14 }}>{kin?.relationship || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <KinOfEmergencyModal
                open={isFormModalOpen}
                kin={kin}
                customer={customer}
                afterSave={setKinOfEmergencyDetails}
                onClose={() => {
                    setIsFormModalOpen(false);
                }}
            />
        </>
    );
};

KinOfEmergency.propTypes = {
    customer: PropTypes.object.isRequired,
    classes: PropTypes.array
};

export default withStyles(styles)(KinOfEmergency);
