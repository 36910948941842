import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getAnswersFromForm } from '../../collums-constants/utils';
import { ORGANISATION_FORMS_TARGET } from '../../collums-constants';

const FormContext = createContext({});

export const FormProvider = ({ children, value }) => {
    const [data, setData] = useState(
        value.defaultData || {
            name: '',
            description: '',
            tag: null,
            isForAllTreatments: false,
            fields: [],
            consent: '',
            isFor: [ORGANISATION_FORMS_TARGET.CLIENT]
        }
    );
    const [errors, setErrors] = useState([]);
    const isJourney = value.isJourney;
    const isReadOnly = value.isReadOnly;
    const signatureAlwaysEmpty = value?.signatureAlwaysEmpty || false; // set signature fields empty by default and make them unlocked even if isReadyOnly is set.
    const additionalProps = value.additionalProps || {};

    useEffect(() => {
        if (data.fields.length && isJourney) {
            const newErrors = getAnswersFromForm(data.fields).errors;
            setErrors(newErrors);
            if (value?.onErrorChange) value.onErrorChange(Boolean(newErrors.length));
        }
        if (value?.onDataChange) value.onDataChange(data);
        //eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (value.defaultData) {
            setData(value.defaultData);
        }
    }, [value.defaultData]);

    return (
        <FormContext.Provider
            value={{ data, setData, isJourney, isReadOnly, errors, additionalProps, signatureAlwaysEmpty }}
        >
            {children}
        </FormContext.Provider>
    );
};

FormProvider.propTypes = {
    children: PropTypes.element,
    value: PropTypes.object
};

export const useForms = () => useContext(FormContext);
