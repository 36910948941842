import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal, withStyles } from '@material-ui/core';
import { viewModalStyles } from '../../styles';
import Moment from 'moment';
import { getDateNoTimezone } from '../../../../collums-components/helpers/timezone';
import CloseBtn from '../../../common/CloseBtn';
import {
    CustomAmountField,
    CustomInfoField,
    CustomTextField,
    CustomSelectField,
    CustomDatePickerField
} from '../../../common/FieldContainer/fields';
import Button from '@material-ui/core/Button';
import MembershipApi from '../../../../api/mambershipApi';
import { toastr } from 'react-redux-toastr';
import { setListShouldUpdate } from '../../../../actions/invoiceActions';
import { useDispatch } from 'react-redux';

const MembershipModal = ({ classes, showMembershipModal, setShowMembershipModal, customer }) => {
    const minDaysAhead = 0;
    const [quantity, setQuantity] = useState(12);
    const [paymentValue, setPaymentValue] = useState('');
    const [totalPaymentValue, setTotalPaymentValue] = useState(null);
    const [membershipName, setMembershipName] = useState('');
    const [firstPaymentDate, setFirstPaymentDate] = useState(
        Moment()
            .add(minDaysAhead, 'days')
            .toDate()
    );
    const dispatch = useDispatch();
    let quantityOptions = Array.from(new Array(36), (_, i) => ({
        label: `${i + 1}`,
        value: `${i + 1}`
    }));
    quantityOptions.unshift({ label: 'Until client cancels', value: 0 });

    const updateTotalPayment = (paymentValue, quantity) => {
        setTotalPaymentValue((paymentValue * quantity).toFixed(2));
    };

    return (
        <Modal open={showMembershipModal} className={classes.modal}>
            <div className={classes.root} style={{ width: 700 }}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={() => setShowMembershipModal(false)} />
                </div>
                <div className={classes.header}>
                    <p>Create New Membership Direct Debit</p>
                </div>

                <div className={classes.content} style={{ fontSize: 18 }}>
                    <form>
                        <Grid container style={{ margin: '0 50px 20px 50px' }}>
                            <CustomInfoField
                                label="Name"
                                value={customer ? `${customer.firstName} ${customer.surname}` : ''}
                            />
                            <CustomTextField
                                label="Membership name"
                                value={membershipName}
                                onChange={event => {
                                    setMembershipName(event.target.value);
                                }}
                            />
                            <CustomAmountField
                                label="Monthly amount"
                                value={paymentValue}
                                onChange={event => {
                                    const value = event.target.value;
                                    if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                        setPaymentValue(value);
                                        updateTotalPayment(value, quantity);
                                    }
                                }}
                            />
                            <CustomSelectField
                                label="No of monthly payments"
                                value={quantity}
                                onChange={event => {
                                    setQuantity(event.target.value);
                                    updateTotalPayment(paymentValue, event.target.value);
                                }}
                                options={quantityOptions}
                            />

                            <CustomDatePickerField
                                label="Date of first payment"
                                value={firstPaymentDate}
                                onChange={value => {
                                    if (!value) return;
                                    const date = Moment.utc(getDateNoTimezone(value));
                                    setFirstPaymentDate(date);
                                }}
                                minDate={Moment()
                                    .add(minDaysAhead, 'days')
                                    .toDate()}
                            />

                            {quantity > 0 && paymentValue > 0 && (
                                <CustomInfoField
                                    label="Total payment amount"
                                    value={totalPaymentValue === null ? '' : '£' + totalPaymentValue}
                                />
                            )}
                        </Grid>
                        <div style={{ fontSize: 14, margin: 10 }}>
                            Completing this form will trigger an email to your client asking for their bank details.
                            Once they have submitted these, the Direct Debit will be live 4-6 days later and the first
                            payment will be taken at that time, regardless of the date selected. Subsequent payments
                            will then be taken on the specified date.
                            <br />
                            If an expected payment is not received, you will be sent a notification.
                        </div>
                        <div className={classes.actions} style={{ width: '100%', margin: '20px auto' }}>
                            <Button
                                className={classes.cancelButton}
                                key="cancel"
                                variant="outlined"
                                onClick={() => setShowMembershipModal(false)}
                            >
                                Back
                            </Button>

                            <Button
                                className={classes.confirmButton}
                                onClick={async () => {
                                    const response = await MembershipApi.createCustomerMembership({
                                        name: membershipName,
                                        firstPayment: firstPaymentDate,
                                        amount: paymentValue,
                                        customer: customer.id,
                                        numberOfPayments: quantity
                                    });
                                    if (!response.success) {
                                        toastr.error(response.message);
                                    } else {
                                        toastr.success('Direct Debit request has been sent');
                                        setShowMembershipModal(false);
                                        dispatch(setListShouldUpdate(true));
                                    }
                                }}
                            >
                                Create Direct Debit
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

MembershipModal.propTypes = {
    classes: PropTypes.object.isRequired,
    showMembershipModal: PropTypes.bool.isRequired,
    setShowMembershipModal: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired
};

export default withStyles(viewModalStyles)(MembershipModal);
