import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
    withStyles,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Dialog,
    Typography,
    CircularProgress,
    IconButton
} from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';

import CloseIcon from '@material-ui/icons/Close';

import StyledTableRow from '../../../common/StyledTableRow';
import { viewModalStyles } from '../../styles';

import CourseApi from '../../../../api/courseApi';
import AppointmentApi from '../../../../api/appointmentApi';
import ServiceApi from '../../../../api/serviceApi';

import { closeCourseHistoryModal } from '../../../../actions/courseActions';

import { getFormattedDate, getFormattedTime } from '../../../../services/helpers';
import { calculateFinalPrice } from '../../../../collums-constants/utils';
import { toLocaleString } from '../../../../collums-components/helpers/index';
import moment from 'moment';
import { APPOINTMENT_STATUS_TYPES } from '../../../../collums-constants';

const CourseHistoryModal = ({ classes }) => {
    const dispatch = useDispatch();

    const [appts, setAppts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [availableServices, setAvailableServices] = useState([]);

    const course = useSelector(state => state.course.selectedCourse);
    const isVisible = useSelector(state => state.course.showCourseHistoryModal);

    const closeModal = () => {
        dispatch(closeCourseHistoryModal());
    };

    const redemptionValue = (() => {
        if (course && course.invoiceItem) {
            if (Array.isArray(course.additionalServicesOryg) && course.additionalServicesOryg.length) {
                return toLocaleString(course.grossAmount / course.quantity);
            }
            return toLocaleString(calculateFinalPrice(course.invoiceItem) / course.quantity);
        }
        return 0;
    })();

    const errorHandler = e => {
        console.error(e);
        toastr.error('There was an issue with your request, please try opening the modal again');
        closeModal();
    };

    const handleRenewAppt = async apptId => {
        if (apptId) {
            setIsLoading(true);
            await AppointmentApi.renewAppt(apptId);
            const response = await CourseApi.getCourseAppointments(course.id);
            setAppts(response);
            setIsLoading(false);
        }
    };

    const formatVoidCourseDate = course => {
        return format(new Date(course.voidedAt), 'dd/MM/yyyy HH:mm');
    };

    useEffect(() => {
        async function getCourseAppts() {
            try {
                if (isVisible && course) {
                    const response = await CourseApi.getCourseAppointments(course.id);
                    setAppts(
                        response.filter(
                            app =>
                                app.status !== APPOINTMENT_STATUS_TYPES.CANCELED &&
                                app.status !== APPOINTMENT_STATUS_TYPES.NO_SHOW &&
                                app.service.id === course.service
                        )
                    );
                    setIsLoading(false);
                }
            } catch (e) {
                errorHandler(e);
            }
        }

        getCourseAppts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible, course]);

    useEffect(() => {
        if (isVisible === false) {
            setIsLoading(true);
        }
    }, [isVisible]);

    useEffect(() => {
        (async () => {
            if (!course) return;
            if (!course.course.service[0]) {
                toastr.error('Course does not have a service');
                closeModal();
                return;
            }
            try {
                const service = await ServiceApi.getService(course.course.service[0]);
                if (course.quantity - appts.length > 0) {
                    const availableServices = new Array(course.quantity - appts.length).fill(null).map((el, index) => {
                        // -- redeemed services
                        let serviceName = service.name;
                        if (course.additionalServices?.id && course.additionalServices.serviceDetails[0]) {
                            serviceName = course.additionalServices.serviceDetails[0].name;
                        }
                        if (
                            course.redeemedServices?.length &&
                            index <= (course.redeemedServices?.length || 0) - 1 &&
                            course.redeemedServices[index]
                        ) {
                            const redeemedDetails = course.redeemedServices[index];
                            return {
                                service: serviceName,
                                price: redemptionValue,
                                status: redeemedDetails.redeemedBy ? 'Manually Redeemed' : 'Unavailable',
                                redeemedDetails
                            };
                        }

                        // -- available services
                        return {
                            service: serviceName,
                            price: redemptionValue,
                            status: 'Available',
                            redeemedDetails: {}
                        };
                    });
                    setAvailableServices(availableServices);
                } else {
                    setAvailableServices([]);
                }
            } catch (e) {
                errorHandler(e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course, appts]);

    const getDate = appt => {
        if (!course) return '';
        if (appt.status !== 'Available' && appt?.redeemedDetails?.redeemedAt)
            return moment(appt.redeemedDetails.redeemedAt).format('DD/MM/YYYY HH:mm');
        if (appt.status !== 'Available') return 'Date unavailable';
        return '';
    };

    const getSortableDate = appt => {
        if (!course) return '';
        if (appt.status !== 'Available' && appt?.redeemedDetails?.redeemedAt)
            return moment(appt.redeemedDetails.redeemedAt).format('YYYY-MM-DDTHH:mm:ss.000Z');
        if (appt.status !== 'Available') return 'Date unavailable';
        return '';
    };

    const getPractitioner = appt => {
        if (!course) return '';
        if (appt.status !== 'Available' && appt?.redeemedDetails?.redeemedBy) return appt.redeemedDetails.redeemedBy;
        return '';
    };

    const getLocation = appt => {
        if (!course) return '';
        if (appt.status === 'Available') return '';
        return course?.location?.accountName || '';
    };

    const rows = appts.map(appt => {
        let serviceName = appt.service ? appt.service.name : '';
        return {
            id: appt.id,
            dateTime: getFormattedDate(appt.event.start) + ' ' + getFormattedTime(appt.event.start),
            date: appt.event.start,
            service: serviceName,
            status: appt?.status || 'Unknown',
            redemptionValue: redemptionValue,
            practitioner: appt.practitioner ? appt.practitioner.displayName : '',
            location: appt.clinic?.accountName || '',
            courseData: appt.courseData
        };
    });

    if (availableServices && availableServices?.length) {
        for (let i = 0; i < availableServices.length; i++) {
            const appt = availableServices[i];
            rows.push({
                id: appt.id,
                dateTime: getDate(appt),
                date: getSortableDate(appt),
                service: appt.service,
                status: appt.status,
                redemptionValue: appt.price,
                practitioner: getPractitioner(appt),
                location: getLocation(appt)
            });
        }
    }

    const rowsSorted = rows.sort((a, b) => {
        const dateA = a.date ? new Date(a.date) : null;
        const dateB = b.date ? new Date(b.date) : null;

        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        return dateA - dateB;
    });

    return (
        <Dialog
            open={isVisible}
            maxWidth="1000"
            className={classes.dialog}
            onBackdropClick={() => closeModal()}
            onEscapeKeyDown={() => closeModal()}
        >
            <div className={classes.root}>
                <IconButton className={classes.closeBtn} onClick={closeModal}>
                    <CloseIcon />
                </IconButton>
                <div className={classes.header}>
                    <p>{`${course && course.name ? course.name : ''} ${
                        course?.additionalServices?.id ? 'Package' : 'Course'
                    } History`}</p>
                    {course && course.voidedAt && (
                        <p style={{ color: 'red' }}>{`Course voided ${formatVoidCourseDate(course)} by ${
                            course.voidedBy?.displayName
                        }`}</p>
                    )}
                </div>
                {!isLoading ? (
                    <div className={classes.content} style={{ marginBottom: 50 }}>
                        {(appts && appts.length) || (availableServices && availableServices.length) ? (
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeader}>Date/Time</TableCell>
                                        <TableCell className={classes.tableHeader}>#</TableCell>
                                        <TableCell className={classes.tableHeader}>Service</TableCell>
                                        <TableCell className={classes.tableHeader}>Status</TableCell>
                                        <TableCell className={classes.tableHeader}>Redemption value</TableCell>
                                        <TableCell className={classes.tableHeader}>Practitioner</TableCell>
                                        <TableCell className={classes.tableHeader}>Location</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rowsSorted.map((appt, index) => {
                                        return (
                                            <StyledTableRow key={appt.id}>
                                                <TableCell>{appt.dateTime}</TableCell>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{appt.service}</TableCell>
                                                <TableCell>
                                                    {['Available', 'Scheduled'].includes(appt.status) &&
                                                    course?.voidedAt
                                                        ? 'Unavailable'
                                                        : appt.status}
                                                </TableCell>
                                                <TableCell>{redemptionValue}</TableCell>
                                                <TableCell>{appt.practitioner}</TableCell>
                                                <TableCell>{appt.location}</TableCell>
                                                {appt.courseData && appt.status !== 'Available' && (
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={() => handleRenewAppt(appt.id)}
                                                            className={classes.iconButton}
                                                        >
                                                            <ReplayIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                )}
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography variant="h5">No appointments for this course were found.</Typography>
                        )}
                    </div>
                ) : (
                    <CircularProgress />
                )}
            </div>
        </Dialog>
    );
};

CourseHistoryModal.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(viewModalStyles)(CourseHistoryModal);
