import React from 'react';
import { withStyles } from '@material-ui/core';
import { GenericError } from '../../../assets/icons';
import styles from './styles';
import PropTypes from 'prop-types';

function GenericErrorBoundaryFallback({ classes }) {
    return (
        <div className={classes.errorMessageContainer}>
            <GenericError classes={classes.errorIcon} />
            <h1 className={classes.errorTitle}>Something went wrong</h1>
            <p className={classes.errorMessage}>
                We apologise for the inconvenience.
                <br /> Our team have been made aware of this problem.
            </p>
        </div>
    );
}

GenericErrorBoundaryFallback.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GenericErrorBoundaryFallback);
