import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Typography, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import AuthApi from '../../../api/AuthApi';
import PractitionerApi from '../../../api/PractitionerApi';
import LoadingScreen from './../loadingScreen';
import {
    Asterisk,
    Title,
    ContainerNumber,
    PINStaffContainer,
    LoginAvatar,
    Avatar,
    PinPadButtonContainer,
    PinPadButton,
    PinCardTitle
} from './styles';
import { useCookies } from 'react-cookie';
import { TOKEN } from '../../../../collums-constants/storageKeys';
import { validateJobPermissions } from '../../../helpers';
import Intercom from '../../../helpers/Intercom';
import { BroadcastChannel } from 'broadcast-channel';
import { DEFAULT_PHOTO } from '../../../../collums-constants';

const bcOptions = { type: 'localstorage', webWorkerSupport: false };
const authBroadcastChannel = new BroadcastChannel('collums-lockscreen', bcOptions);

const theme = createMuiTheme({
    props: {
        MuiButtonBase: {
            disableRipple: false,
            disableTouchRipple: false
        }
    }
});

const ShowPINModal = ({
    // isOpen,
    // setOpen,
    onCorrectPin,
    style = {},
    onEnterPin = () => {}
}) => {
    const [allPractitioners, setAllPractitioners] = useState([]);
    const [loginAsPractitioner, setLoginAsPractitioner] = useState(null);
    const [pin, setPin] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const [cookies, setCookie] = useCookies([TOKEN, 'isLocked']);
    const [isOpen, setOpen] = useState(cookies.isLocked);

    authBroadcastChannel.onmessage = async event => {
        const jsonData = JSON.parse(event);
        const { token, user } = jsonData;

        if (!token) {
            setOpen(true);
        } else {
            await handleNewToken({ token, user });
        }
    };

    const handleNewToken = async ({ token: newToken, user }) => {
        if (!newToken) {
            return;
        }
        const environment = {};
        if (
            window.location.hostname !== 'localhost' &&
            window.location.hostname !== 'devcollumsapi.net' &&
            window.location.hostname !== '192.168.15.204'
        ) {
            environment.path = '/';
            environment.domain = '.collums.co.uk';
        }

        localStorage.setItem('token', newToken);

        const res = await AuthApi.getMe();

        await validateJobPermissions(newToken, res, process.env.REACT_APP_NAME);
        await Intercom.updateUser(user ? user : null);
        Intercom.showLauncher();

        onEnterPin(res, newToken);

        if (onCorrectPin) await onCorrectPin();

        setIsLoading(true);
        setIsRefreshing(true);

        setCookie('token', newToken, environment);
        setCookie('isLocked', false, environment);

        window.location.reload();
    };

    useEffect(() => {
        if (isRefreshing) return;

        const isLocked = cookies.isLocked === 'true';
        setOpen(isLocked);
    }, [cookies, isRefreshing]);

    useEffect(() => {
        (async () => {
            const allPractitioners = await PractitionerApi.listPinLoginPractitioners();
            setAllPractitioners(allPractitioners);
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (isOpen === 'true' || isOpen === true) {
            Intercom.hideLauncher();
        }
    }, [isOpen]);

    const checkPin = useCallback(
        async _pin => {
            if (_pin?.length === 5) {
                try {
                    setIsLoading(true);
                    // calls verify-pin endpoint
                    const isCorrectPIN = await AuthApi.isCorrectPIN({
                        pin: _pin?.join('').toString(),
                        practitionerId: loginAsPractitioner?.id
                    });

                    const environment = {};
                    if (
                        window.location.hostname !== 'localhost' &&
                        window.location.hostname !== 'devcollumsapi.net' &&
                        window.location.hostname !== '192.168.15.204'
                    ) {
                        environment.path = '/';
                        environment.domain = '.collums.co.uk';
                    }

                    if (isCorrectPIN) {
                        const token = isCorrectPIN.token;
                        if (token) {
                            authBroadcastChannel.postMessage(JSON.stringify({ token: token, user: isCorrectPIN.user }));
                            handleNewToken({ token: token, user: isCorrectPIN.user });
                        } else {
                            await Intercom.updateUser();
                            Intercom.showLauncher();
                        }
                    }
                } finally {
                    setIsLoading(false);
                }
            }
        },
        // eslint-disable-next-line
        [loginAsPractitioner]
    );

    const practitionerAvatar = practitioner => {
        const defaultAvatar =
            practitioner.gender === 'Female' ? DEFAULT_PHOTO.DEFAULT_FEMALE : DEFAULT_PHOTO.DEFAULT_MALE;

        // const avatar = practitioner.avatar
        //     ? 'https://collums.imgix.net/' + practitioner.avatar.split('/').pop() + '?w=128&h=128&mask=ellipse&fit=crop'
        //     : null;

        return practitioner.avatar ?? defaultAvatar;
    };

    const returnToStaffSelect = () => {
        setLoginAsPractitioner(null);
        setPin([]);
    };

    const onClickNumber = value => {
        if (pin.length < 5) {
            setPin(nPin => [...nPin, value]);
        }
        checkPin([...pin, value]);
    };

    const onClickPractitioner = value => {
        setLoginAsPractitioner(value);
    };

    const removeLastNumber = () => {
        const nPin = [...pin];
        nPin.pop();
        setPin(nPin);
    };

    const handleNumberType = useCallback(
        ({ key }) => {
            if (!isOpen) return;
            if (key === 'Backspace') setPin(oldPin => oldPin.slice(0, -1));
            if (isNaN(key)) return;
            if (pin.length < 5) {
                setPin(oldPin => [...oldPin, Number(key)]);
            }
            checkPin([...pin, Number(key)]);
        },
        [checkPin, pin, isOpen]
    );

    useEffect(() => {
        window.addEventListener('keyup', handleNumberType);
        return () => {
            window.removeEventListener('keyup', handleNumberType);
        };
    }, [pin, handleNumberType]);

    const StyledPinPadButton = useCallback(content => {
        return (
            <PinPadButtonContainer
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                justifyContent="center"
                key={`${content}-login-avatar`}
                style={{ display: 'flex' }}
            >
                <PinPadButton onClick={content.onClick}>{content.children}</PinPadButton>
            </PinPadButtonContainer>
        );
    }, []);

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <PINStaffContainer style={{ position: 'absolute', display: isOpen ? 'block' : 'none', ...style }}>
                    {isLoading && <LoadingScreen />}
                    {loginAsPractitioner ? (
                        <Box
                            container
                            mx="auto"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            width="90%"
                            height="90%"
                            bgcolor="#ffffff"
                        >
                            <LoginAvatar>
                                <Avatar alt="Staff avatar" src={practitionerAvatar(loginAsPractitioner)}></Avatar>
                                <PinCardTitle align="center" variant="h5" style={{ fontWeight: 450 }}>
                                    {loginAsPractitioner.displayName}
                                </PinCardTitle>
                            </LoginAvatar>
                            <Box display="flex" width="100%" mt={2} className="header">
                                <Title align="center" variant="h5" style={{ fontWeight: 500 }}>
                                    Please enter your PIN
                                </Title>
                            </Box>
                            <Box width="1rem" height="1rem" />
                            <ContainerNumber display="flex" width="100%" justifyContent="center" height="14px">
                                {pin.map((v, idx) => {
                                    return (
                                        <Typography key={idx}>
                                            <Asterisk />
                                        </Typography>
                                    );
                                })}
                            </ContainerNumber>
                            <Box width="1rem" height="1rem" />
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justifyContent="center"
                                style={{ maxWidth: '300px' }}
                            >
                                <StyledPinPadButton onClick={() => onClickNumber(1)}>1</StyledPinPadButton>
                                <StyledPinPadButton onClick={() => onClickNumber(2)}>2</StyledPinPadButton>
                                <StyledPinPadButton onClick={() => onClickNumber(3)}>3</StyledPinPadButton>
                                <StyledPinPadButton onClick={() => onClickNumber(4)}>4</StyledPinPadButton>
                                <StyledPinPadButton onClick={() => onClickNumber(5)}>5</StyledPinPadButton>
                                <StyledPinPadButton onClick={() => onClickNumber(6)}>6</StyledPinPadButton>
                                <StyledPinPadButton onClick={() => onClickNumber(7)}>7</StyledPinPadButton>
                                <StyledPinPadButton onClick={() => onClickNumber(8)}>8</StyledPinPadButton>
                                <StyledPinPadButton onClick={() => onClickNumber(9)}>9</StyledPinPadButton>
                                <StyledPinPadButton onClick={returnToStaffSelect}>&lt;</StyledPinPadButton>
                                <StyledPinPadButton onClick={() => onClickNumber(0)}>0</StyledPinPadButton>
                                <StyledPinPadButton onClick={removeLastNumber} noPadding>
                                    Del
                                </StyledPinPadButton>
                            </Grid>
                        </Box>
                    ) : (
                        <Box
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            mt={3}
                            mx="auto"
                            width="90%"
                            bgcolor="#ffffff"
                            maxWidth="800px"
                        >
                            <Box width="100%" mt={1} className="header">
                                <Title align="center" variant="h5" style={{ fontWeight: 500 }}>
                                    Device locked.
                                </Title>
                                <Title align="center" variant="h5" style={{ fontWeight: 500 }}>
                                    Please select your practitioner card from below to continue
                                </Title>
                            </Box>
                            <Box width="1rem" height="1rem" />
                            <Grid container spacing={1} direction="row" justifyContent="center">
                                {allPractitioners.map((p, index) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                            justifyContent="center"
                                            key={`${index}-login-avatar`}
                                            style={{ display: 'flex' }}
                                        >
                                            <LoginAvatar onClick={() => onClickPractitioner(p)}>
                                                <Avatar alt="Staff avatar" src={practitionerAvatar(p)}></Avatar>
                                                <PinCardTitle
                                                    align="center"
                                                    variant="h5"
                                                    mt={3}
                                                    style={{ fontWeight: 450 }}
                                                >
                                                    {p.displayName}
                                                </PinCardTitle>
                                            </LoginAvatar>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    )}
                </PINStaffContainer>
            </MuiThemeProvider>
        </>
    );
};

ShowPINModal.propTypes = {
    style: PropTypes.object,
    onCorrectPin: PropTypes.func,
    onEnterPin: PropTypes.func
};

export default ShowPINModal;
