import Moment from 'moment';
import isArray from 'lodash/isArray';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import flattenDeep from 'lodash/flattenDeep';
import { STAFF_WEEK } from '../../constants/viewModes';
import getMomentToCalendar from '../../services/helpers/getCalendarMoment';
import { generateDefaultWeek } from '../../services/helpers';

const getClinicTimes = (daySchedule, currentClinic, viewMode) => {
    const clearSecondsFromTimeText = time => {
        if (time) {
            time = time.split(':');
            time = time.slice(0, time.length > 2 ? -1 : time.length).join(':');
        }
        return time;
    };

    const getClinicPropertiesForTheDay = (selectedDate = daySchedule.date) => {
        const date = Moment.isMoment(selectedDate) ? selectedDate : Moment(selectedDate);
        let startM,
            endM,
            startT,
            endT,
            isOpen = true;
        if (isArray(currentClinic.time)) {
            const dayOfWeek = date.isoWeekday() - 1 || 0;
            startM = Moment.duration(
                clearSecondsFromTimeText(currentClinic.time[dayOfWeek]?.start || currentClinic.time[0].start),
                'H:mm'
            );
            startT = startM.asHours();
            endM = Moment.duration(
                clearSecondsFromTimeText(currentClinic.time[dayOfWeek]?.end || currentClinic.time[0].end),
                'H:mm'
            );
            endT = endM.asHours();
            isOpen = !currentClinic.time[dayOfWeek]?.isClosed;
            if (!isOpen) {
                startM = Moment.duration(clearSecondsFromTimeText('12:00:00'), 'H:mm');
                startT = startM.asHours();
                endM = Moment.duration(clearSecondsFromTimeText('12:00:00'), 'H:mm');
                endT = endM.asHours();
            }
            if ((currentClinic.holidays || []).length) {
                let holidaysStart = null;
                let holidaysEnd = null;

                currentClinic.holidays.forEach(el => {
                    const elDate = Moment.isMoment(el.date) ? el.date : Moment(el.date);
                    if (elDate.isSame(date, 'day')) {
                        if (el.start) {
                            const startMoment = Moment(elDate.format('YYYY-MM-DD') + 'T' + el.start);

                            if (!holidaysStart || startMoment.isBefore(holidaysStart)) {
                                holidaysStart = startMoment;
                            }
                        }

                        if (el.end) {
                            const endMoment = Moment(elDate.format('YYYY-MM-DD') + 'T' + el.end);

                            if (!holidaysEnd || endMoment.isAfter(holidaysEnd)) {
                                holidaysEnd = endMoment;
                            }
                        }

                        isOpen = !el.isClosed;
                    }
                });

                if (holidaysStart) {
                    startM = Moment.duration(holidaysStart.format('H:mm'), 'H:mm');
                    startT = startM.asHours();
                }

                if (holidaysEnd) {
                    endM = Moment.duration(holidaysEnd.format('H:mm'), 'H:mm');
                    endT = endM.asHours();
                }
            }
        } else {
            startT = Moment.duration(currentClinic.time.start, 'H:mm').asHours();
            endT = Moment.duration(currentClinic.time.end, 'H:mm').asHours();
        }

        const soloPractitioner = daySchedule.schedules.find(schedule => schedule?.practitioner?.isSoloPractitioner);
        if (soloPractitioner && soloPractitioner.practitionerSchedules.length) {
            let minScheduleStart = soloPractitioner.practitionerSchedules[0].start;
            let maxScheduleEnd = soloPractitioner.practitionerSchedules[0].end;
            for (const practitionerSchedule of soloPractitioner.practitionerSchedules) {
                if (practitionerSchedule.start < minScheduleStart) {
                    minScheduleStart = practitionerSchedule.start;
                }
                if (practitionerSchedule.end > maxScheduleEnd) {
                    maxScheduleEnd = practitionerSchedule.end;
                }
            }
            startM = Moment.duration(clearSecondsFromTimeText(minScheduleStart + ':00'), 'H:mm');
            startT = startM.asHours();
            endM = Moment.duration(clearSecondsFromTimeText(maxScheduleEnd + ':00'), 'H:mm');
            endT = endM.asHours();
        }

        return {
            startMoment: startM,
            endMoment: endM,
            startTime: startT,
            endTime: endT,
            isClinicOpen: isOpen
        };
    };

    const getClinicDateStartAndEnd = (startT, endT, selectedDate = daySchedule.date) => {
        const date = Moment.isMoment(selectedDate) ? selectedDate : Moment(selectedDate);

        let [calendarStartHour, calendarEndHour] = (() => {
            const startHr = Math.floor(startT) >= 0 ? Math.floor(startT) : 0;
            const endHr = Math.ceil(endT) <= 24 ? Math.ceil(endT) : 24;
            let minScheduleStart = startHr;
            let maxScheduleEnd = endHr;
            // if is solo practitioner extend calendar by his schedule
            for (const schedule of daySchedule.schedules) {
                if (schedule?.practitioner?.isSoloPractitioner) {
                    for (const practitionerSchedule of schedule.practitionerSchedules) {
                        const scheduleStart = parseInt(practitionerSchedule.start);
                        const scheduleEnd = parseInt(practitionerSchedule.end);
                        if (scheduleStart < minScheduleStart) {
                            minScheduleStart = scheduleStart;
                        }
                        if (scheduleEnd > maxScheduleEnd) {
                            maxScheduleEnd = scheduleEnd;
                        }
                    }
                }
            }
            minScheduleStart -= 1;
            maxScheduleEnd += 1;
            if (minScheduleStart < 0) {
                minScheduleStart = 0;
            }
            if (maxScheduleEnd > 24) {
                maxScheduleEnd = 24;
            }

            const defaultReturn = [minScheduleStart, maxScheduleEnd];
            try {
                const apptsOutOfTime = flattenDeep(
                    daySchedule.schedules
                        .filter(el => {
                            if (viewMode !== STAFF_WEEK) return true;
                            return date.isSame(el.date, 'days');
                        })
                        .map(schedule => {
                            return (schedule.appointments || [])
                                .map(appt => {
                                    const apptStart = Moment.duration(
                                        appt.event.start.format('HH:mm'),
                                        'HH:mm'
                                    ).asHours();
                                    const apptEnd = Moment.duration(appt.event.end.format('HH:mm'), 'HH:mm').asHours();
                                    return apptStart < defaultReturn[0] || apptEnd > defaultReturn[1]
                                        ? { apptStart, apptEnd }
                                        : undefined;
                                })
                                .filter(el => el);
                        })
                );
                const apptsOutsideEndTime = maxBy(apptsOutOfTime, 'apptEnd');
                if (apptsOutsideEndTime.apptEnd > defaultReturn[1]) {
                    defaultReturn[1] = Math.ceil(apptsOutsideEndTime.apptEnd);
                }

                const apptsOutsideStartTime = minBy(apptsOutOfTime, 'apptStart');
                if (apptsOutsideStartTime.apptStart < defaultReturn[0])
                    defaultReturn[0] = Math.floor(apptsOutsideStartTime.apptStart);
            } catch (err) {
                return defaultReturn;
            }
            return defaultReturn;
        })();

        if (calendarStartHour < 0) calendarStartHour = 0;
        if (calendarEndHour > 24) calendarEndHour = 24;
        return { calendarStartHour, calendarEndHour };
    };

    const { startTime, endTime, startMoment, endMoment } = getClinicPropertiesForTheDay();

    let { calendarStartHour, calendarEndHour } = getClinicDateStartAndEnd(startTime, endTime);

    const clinicWeekTimes = (() => {
        if (viewMode === STAFF_WEEK) {
            const allSchedules = (daySchedule.schedules.length > 0
                ? daySchedule.schedules
                : generateDefaultWeek(daySchedule.date)
            ).map(el => {
                const dateOpts = getClinicPropertiesForTheDay(el.date);

                const calendarOpts = getClinicDateStartAndEnd(dateOpts.startTime, dateOpts.endTime);

                return { ...dateOpts, ...calendarOpts, date: el.date };
            });
            const minStartHour = minBy(allSchedules, 'calendarStartHour');
            const maxEndHour = maxBy(allSchedules, 'calendarEndHour');
            if (minStartHour.calendarStartHour < calendarStartHour) calendarStartHour = minStartHour.calendarStartHour;
            if (maxEndHour.calendarEndHour > calendarEndHour) calendarEndHour = maxEndHour.calendarEndHour;

            return allSchedules.map(el => {
                return {
                    clinicStartMoment: getMomentToCalendar(el.startTime, el.date),
                    clinicEndMoment: getMomentToCalendar(el.endTime, el.date),
                    date: el.date
                };
            });
        }
        return [];
    })();

    return {
        clinicStartTime: startMoment.format('H:mm'),
        clinicEndTime: endMoment.format('H:mm'),
        calendarStartHour,
        calendarEndHour,
        clinicStartHour: startTime,
        clinicEndHour: endTime,
        clinicWeekTimes
    };
};

export default getClinicTimes;
