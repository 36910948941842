import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';

import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';
import { toLocaleString } from '../../../../collums-components/helpers';
import ChangeDirectDebitStatus from './ChangeDirectDebitStatus';

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
    TableSortLabel,
    TablePagination,
    Paper,
    TableContainer,
    Button
} from '@material-ui/core';
import { accountViewStyles as styles } from './styles';

import { StyledTableRow } from '../StyledTableRow';
import tableHeaders from './tableHeaders';
import { listCustomerMemberships } from '../../../../api/mambershipApi';
import { loadInvoice, setListShouldUpdate } from '../../../../actions/invoiceActions';

const MembershipView = ({ classes }) => {
    const [memberships, setMemberships] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const customer = useSelector(state => state.customer.customer);
    const dispatch = useDispatch();
    const [showChangeDirectDebitStatusModal, setShowChangeDirectDebitStatusModal] = useState(null);

    const shouldListUpdate = useSelector(state => state.invoice.shouldListUpdate);

    const [order, setOrder] = useState({
        order: 'desc',
        orderBy: 'invoice.createdAt'
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleReorder = headCellId => {
        const data = {
            order: order?.order === 'asc' ? 'desc' : 'asc',
            orderBy: headCellId
        };

        fetch(data);
        setOrder(data);
    };

    useEffect(() => {
        if (!customer) return;
        fetch();
        /* eslint-disable-next-line */
    }, [customer, page, rowsPerPage]);

    useEffect(() => {
        if (shouldListUpdate) {
            fetch();
            dispatch(setListShouldUpdate(false));
        }
        /*eslint-disable-next-line */
    }, [shouldListUpdate]);

    const openInvoice = invoice => {
        dispatch(loadInvoice(invoice.id));
    };

    const fetch = async orderParam => {
        try {
            setIsLoading(true);
            const state = orderParam || order;
            const queryOrder = `${state.order === 'asc' ? '' : '-'}${state.orderBy}`;
            const response = await listCustomerMemberships({
                customer: customer.id,
                order: queryOrder,
                page,
                rowsPerPage
            });
            setMemberships(response.data);
            setCount(response.count);
            setIsLoading(false);
        } catch (e) {
            toastr.error('Something went wrong (code: c0033b)');
        }
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Box m={4}>
                <Box>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="medium" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {tableHeaders.map(headCell => (
                                        <TableCell
                                            className={classes.tableHeader}
                                            key={headCell.id}
                                            align={headCell.numeric ? 'right' : 'left'}
                                            padding={headCell.disablePadding ? 'All' : 'default'}
                                            sortDirection={order.orderBy === headCell.id ? order.order : false}
                                        >
                                            {headCell.reordable ? (
                                                <TableSortLabel
                                                    active={order.orderBy === headCell.id}
                                                    direction={order.orderBy === headCell.id ? order.order : 'asc'}
                                                    onClick={() => handleReorder(headCell.id)}
                                                >
                                                    {headCell.label}
                                                    {order.orderBy === headCell.id ? (
                                                        <span hidden>
                                                            {order.order === 'desc'
                                                                ? 'sorted descending'
                                                                : 'sorted ascending'}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            ) : (
                                                headCell.label
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {memberships.map((membership, index) => {
                                    return (
                                        <StyledTableRow key={`${index}-body-table-row`}>
                                            <TableCell
                                                className={`${classes.tableBodyCell} ${classes.capitalize} ${
                                                    membership.status === 'overdue' ? classes.fontColorRed : ''
                                                }`}
                                            >
                                                {membership.status}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {membership?.createdAt
                                                    ? membership.createdAt.format('DD/MM/YYYY HH:mm')
                                                    : ''}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {membership?.formCompletedAt
                                                    ? membership.formCompletedAt.format('DD/MM/YYYY HH:mm')
                                                    : ''}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>{membership.name}</TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {membership?.invoice?.code && (
                                                    <Button
                                                        color="primary"
                                                        className={classes.invoiceButton}
                                                        onClick={() => openInvoice(membership.invoice)}
                                                    >
                                                        {membership.invoice.code}
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {toLocaleString(membership.amount)}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {membership.numberOfPayments
                                                    ? membership.numberOfPayments
                                                    : 'Until cancellation'}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {membership?.firstPayment
                                                    ? membership.firstPayment.format('DD/MM/YYYY HH:mm')
                                                    : ''}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {membership?.latestPayedAt
                                                    ? membership.latestPayedAt.format('DD/MM/YYYY HH:mm')
                                                    : '-'}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {membership.paymentsTaken}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {membership.numberOfPayments ? membership.paymentsLeft : ''}
                                            </TableCell>
                                            <TableCell className={`${classes.tableBodyCell} ${classes.rightAligned}`}>
                                                {toLocaleString(membership.amountPaid)}
                                            </TableCell>
                                            <TableCell
                                                className={`${classes.tableBodyCell} ${classes.rightAligned} ${
                                                    membership.amountOutstanding ? classes.fontColorRed : ''
                                                }`}
                                            >
                                                {membership.amountOutstanding
                                                    ? toLocaleString(membership.amountOutstanding)
                                                    : ''}
                                            </TableCell>

                                            {/*<TableCell className={classes.tableBodyCell}>*/}
                                            {/*    {membership.soldBy?.displayName}*/}
                                            {/*</TableCell>*/}
                                            <TableCell className={classes.tableBodyCell}>
                                                {!['void'].includes(membership.status) && (
                                                    <Button
                                                        onClick={() => {
                                                            setShowChangeDirectDebitStatusModal({
                                                                itemId: membership.itemId,
                                                                type: membership.type
                                                            });
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={(e, page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={e => {
                            setRowsPerPage(parseInt(e.target.value, 10));
                            setPage(0);
                        }}
                        classes={{ root: classes.tablePagination }}
                    />
                </Box>
            </Box>
            <ChangeDirectDebitStatus
                showChangeDirectDebitStatusModal={showChangeDirectDebitStatusModal}
                setShowChangeDirectDebitStatusModal={setShowChangeDirectDebitStatusModal}
            />
        </>
    );
};

MembershipView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MembershipView);
