import CustomerApi from '../api/customerApi';
import insuranceApi from '../api/insuranceApi';
import TreatmentApi from '../api/treatmentApi';
import { showError } from '../services/interceptors';
import * as actions from './actionTypes';
import { toastr } from 'react-redux-toastr';
import CourseApi from '../api/courseApi';
import { uploadCustomerAvatar } from '../collums-components/api/CustomerApi';
import kinOfEmergencyApi from '../api/kinOfEmergencyApi';

export function loadCustomerSuccess(customer) {
    return { type: actions.LOAD_CUSTOMER_SUCCESS, payload: customer };
}

export function clearCustomer() {
    return { type: actions.CLEAR_CUSTOMER };
}

export function loadCustomerRequest() {
    return { type: actions.LOAD_CUSTOMER_REQUEST };
}

export function updateCustomerRawAccountBalance(balance) {
    return { type: actions.UPDATE_USER_RAW_ACCOUNT_BALANCE, payload: balance };
}

export function updateCustomerAccountBalance(balance) {
    return { type: actions.UPDATE_USER_ACCOUNT_BALANCE, payload: balance };
}

export function loadCustomer(id) {
    return dispatch => {
        dispatch(loadCustomerRequest());
        CustomerApi.findCustomer(id)
            .then(response => {
                if (response?.id) {
                    return dispatch(loadCustomerSuccess(response));
                }
                if (response instanceof Error) {
                    showError(response?.message);
                }
            })
            .catch(showError);
    };
}

export function loadCustomerRawAccountBalance(id) {
    return dispatch => {
        CustomerApi.getRawAccountBalance(id)
            .then(response => {
                if (response instanceof Error) {
                    showError(response?.message);
                } else {
                    dispatch(updateCustomerRawAccountBalance(response));
                }
            })
            .catch(showError);
    };
}

export function loadCustomerAppointmentsSuccess(appointments) {
    return { type: actions.LOAD_CUSTOMER_APPOINTMENTS_SUCCESS, payload: appointments };
}
export function loadCustomerAppointmentsRequest() {
    return { type: actions.LOAD_CUSTOMER_APPOINTMENTS_REQUEST };
}

export function loadCustomerAppointments(customerId, params, callback) {
    if (!params.group) {
        params.group = 'ALL';
    }
    return dispatch => {
        dispatch(loadCustomerAppointmentsRequest());
        CustomerApi.listAppointmentsByCustomer(customerId, params)
            .then(customer => {
                callback && callback();
                return dispatch(loadCustomerAppointmentsSuccess(customer));
            })
            .catch(showError);
    };
}

export function updateGpSuccess(data) {
    toastr.success('Updated succesfully');
    return { type: actions.UPDATE_GP_SUCCESS, payload: data };
}
export function updateGpRequest() {
    return { type: actions.UPDATE_GP_REQUEST };
}

export function updateGp(gpId, data) {
    return dispatch => {
        dispatch(updateGpRequest());
        CustomerApi.setGeneralPractitioner(gpId, data)
            .then(data => {
                return dispatch(updateGpSuccess(data));
            })
            .catch(showError);
    };
}

export function updateInsuranceSuccess(data) {
    toastr.success('Updated succesfully');
    return { type: actions.UPDATE_INSURANCE_SUCCESS, payload: data };
}

export function updateInsuranceRequest() {
    return { type: actions.UPDATE_INSURANCE_REQUEST };
}

export function updateInsurance(insuranceId, data) {
    return dispatch => {
        dispatch(updateInsuranceRequest());
        insuranceApi
            .update(insuranceId, data)
            .then(data => {
                return dispatch(updateInsuranceSuccess(data));
            })
            .catch(showError);
    };
}

export function updateKinOfEmergencySuccess(data) {
    toastr.success('Updated succesfully');
    return { type: actions.UPDATE_KIN_OF_EMERGENCY_SUCCESS, payload: data };
}

export function updateKinOfEmergencyRequest() {
    return { type: actions.UPDATE_KIN_OF_EMERGENCY_REQUEST };
}

export function updateKinOfEmergency(insuranceId, data) {
    return dispatch => {
        dispatch(updateInsuranceRequest());
        kinOfEmergencyApi
            .update(insuranceId, data)
            .then(data => {
                return dispatch(updateKinOfEmergencySuccess(data));
            })
            .catch(showError);
    };
}

export function updateCustomerSuccess(data) {
    toastr.success('Updated succesfully');
    return { type: actions.UPDATE_CUSTOMER_IN_CUSTOMER_VIEW_SUCCESS, payload: data };
}
export function updateCustomerRequest() {
    return { type: actions.UPDATE_CUSTOMER_IN_CUSTOMER_VIEW_REQUEST };
}

export function updateCustomer(customerId, data, file, deletedPhoto) {
    return dispatch => {
        dispatch(updateCustomerRequest());
        return CustomerApi.update(customerId, data)
            .then(async res => {
                if (file) {
                    const url = await uploadCustomerAvatar(customerId, file);
                    res.avatar = url + '?' + new Date().getTime();
                } else if (deletedPhoto) {
                    await CustomerApi.deleteCustomerAvatar(customerId);
                    res.avatar = null;
                }
                return dispatch(updateCustomerSuccess(res));
            })
            .catch(err => {
                toastr.error(err?.response?.data?.message || 'Something went wrong (code: c0002)');
            });
    };
}

export function fetchCustomerMessagesSuccess(data) {
    return { type: actions.FETCH_CUSTOMER_MESSAGES_SUCCESS, payload: data };
}
export function fetchCustomerMessagesRequest() {
    return { type: actions.FETCH_CUSTOMER_MESSAGES_REQUEST };
}

export function fetchCustomerMessages(customerId, params) {
    return dispatch => {
        dispatch(fetchCustomerMessagesRequest());
        CustomerApi.fetchMessagesByCustomer(customerId, params)
            .then(data => {
                return dispatch(fetchCustomerMessagesSuccess(data));
            })
            .catch(showError);
    };
}

export function fetchSearchTreatmentNotesRequest() {
    return { type: actions.FETCH_SEARCH_TREATMENT_NOTES_REQUEST };
}
export function fetchSearchTreatmentNotesSuccess(data) {
    return { type: actions.FETCH_SEARCH_TREATMENT_NOTES_SUCESS, payload: data };
}

export function fetchSearchTreatmentNotes(filters) {
    return dispatch => {
        dispatch(fetchSearchTreatmentNotesRequest());
        TreatmentApi.searchTreatmentNotes(filters)
            .then(response => dispatch(fetchSearchTreatmentNotesSuccess(response.data)))
            .catch(showError);
    };
}

export function fetchCustomerCourses(customer, clinic, excludeExpired = false) {
    return dispatch => {
        CourseApi.getCustomerCourse(customer, clinic, excludeExpired)
            .then(response => dispatch({ type: actions.FETCH_CUSTOMER_COURSES, payload: response }))
            .catch(e => {
                console.error(e);
                toastr.error('Could not load courses', 'Something went wrong (code: c0003)');
                dispatch({ type: actions.FETCH_CUSTOMER_COURSES, payload: [] });
            });
    };
}

export function createMessageSuccess(data) {
    toastr.success('Message sent!');
    return { type: actions.CREATE_MESSAGE_SUCCESS, payload: data };
}
export function createMessageRequest() {
    return { type: actions.CREATE_MESSAGE_REQUEST };
}

export function createMessage(form, searchOptions) {
    return dispatch => {
        dispatch(createMessageRequest());
        CustomerApi.createMessage(form)
            .then(data => {
                dispatch(createMessageSuccess(data));
                return dispatch(fetchCustomerMessages(form.customer, searchOptions));
            })
            .catch(showError);
    };
}
