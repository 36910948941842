import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal, withStyles } from '@material-ui/core';
import { viewModalStyles } from '../../../../components/customer/styles';
import Moment from 'moment';
import { getDateNoTimezone } from '../../../../collums-components/helpers/timezone';
import CloseBtn from '../CloseBtn';
import {
    CustomAmountField,
    CustomInfoField,
    CustomSelectField,
    CustomDatePickerField
} from '../../../../components/common/FieldContainer/fields';
import Button from '@material-ui/core/Button';
import { toastr } from 'react-redux-toastr';
import InvoiceApi from '../../../api/InvoiceApi';

const DirectDebitModal = ({ classes, showDirectDebitModal, setShowDirectDebitModal, amount, invoiceId, reload }) => {
    const minDaysAhead = 0;
    const defaultQuantity = 12;
    const [quantity, setQuantity] = useState(defaultQuantity);
    const [paymentValue, setPaymentValue] = useState(parseFloat((amount / defaultQuantity).toFixed(2)));
    const [totalPaymentValue, setTotalPaymentValue] = useState(amount);
    const [firstPaymentDate, setFirstPaymentDate] = useState(
        Moment()
            .add(minDaysAhead, 'days')
            .toDate()
    );
    let quantityOptions = Array.from(new Array(36), (_, i) => ({
        label: `${i + 1}`,
        value: `${i + 1}`
    }));
    quantityOptions.unshift({ label: 'Until client cancels', value: 0 });

    const updateMonthlyPayment = (totalPaymentValue, quantity) => {
        setPaymentValue((totalPaymentValue / quantity).toFixed(2));
    };

    return (
        <Modal open={showDirectDebitModal} className={classes.modal}>
            <div className={classes.root} style={{ width: 700 }}>
                <div className={classes.closeBtn}>
                    <CloseBtn onClick={() => setShowDirectDebitModal(false)} />
                </div>
                <div className={classes.header}>
                    <p>Create Direct Debit</p>
                </div>

                <div className={classes.content} style={{ fontSize: 18 }}>
                    <form>
                        <Grid container style={{ margin: '0 50px 20px 50px' }}>
                            <CustomAmountField
                                label="Total amount"
                                value={totalPaymentValue}
                                onChange={event => {
                                    const value = event.target.value;
                                    if (/^\d*(\.\d{0,2})?$/.test(value)) {
                                        setTotalPaymentValue(value);
                                        updateMonthlyPayment(value, quantity);
                                    }
                                }}
                            />
                            <CustomSelectField
                                label="No of monthly payments"
                                value={quantity}
                                onChange={event => {
                                    setQuantity(event.target.value);
                                    updateMonthlyPayment(totalPaymentValue, event.target.value);
                                }}
                                options={quantityOptions}
                            />

                            <CustomDatePickerField
                                label="Date of first payment"
                                value={firstPaymentDate}
                                onChange={value => {
                                    if (!value) return;
                                    const date = Moment.utc(getDateNoTimezone(value));
                                    setFirstPaymentDate(date);
                                }}
                                minDate={Moment()
                                    .add(minDaysAhead, 'days')
                                    .toDate()}
                            />

                            {quantity > 0 && paymentValue > 0 && (
                                <CustomInfoField
                                    label="Monthly payment amount"
                                    value={paymentValue === null ? '' : '£' + paymentValue}
                                />
                            )}
                        </Grid>
                        <div style={{ fontSize: 14, margin: 10 }}>
                            Completing this form will trigger an email to your client asking for their bank details.
                            Once they have submitted these, the Direct Debit will be live 4-6 days later and the first
                            payment will be taken at that time, regardless of the date selected. Subsequent payments
                            will then be taken on the specified date.
                            <br />
                            If an expected payment is not received, you will be sent a notification.
                        </div>
                        <div className={classes.actions} style={{ width: '100%', margin: '20px auto' }}>
                            <Button
                                className={classes.cancelButton}
                                key="cancel"
                                onClick={() => setShowDirectDebitModal(false)}
                                variant="outlined"
                            >
                                Back
                            </Button>

                            <Button
                                className={classes.confirmButton}
                                onClick={async () => {
                                    const response = await InvoiceApi.createDirectDebit(invoiceId, {
                                        firstPayment: firstPaymentDate,
                                        amount: paymentValue,
                                        numberOfPayments: quantity
                                    });
                                    if (!response.success) {
                                        toastr.error(response.message);
                                    } else {
                                        toastr.success('Direct Debit request has been sent');
                                        setShowDirectDebitModal(false);
                                        await reload();
                                    }
                                }}
                            >
                                Create Direct Debit
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

DirectDebitModal.propTypes = {
    classes: PropTypes.object.isRequired,
    showDirectDebitModal: PropTypes.bool.isRequired,
    setShowDirectDebitModal: PropTypes.func.isRequired,
    amount: PropTypes.string.isRequired,
    invoiceId: PropTypes.string.isRequired,
    reload: PropTypes.func
};

export default withStyles(viewModalStyles)(DirectDebitModal);
