import AuthApi from '../api/AuthApi';

const ALIGNMENT_LEFT = 'left';

const getCompanyDetails = async () => {
    const organisation = await AuthApi.getOrg();

    if (organisation) {
        if (organisation?.data) {
            if (!organisation?.data?.intercomId) return null;

            return {
                id: organisation?.data.intercomId,
                name: organisation?.data.name
            };
        } else if (organisation?.intercomId) {
            return {
                id: organisation?.intercomId,
                name: organisation?.name
            };
        }
    }

    return null;
};

const Intercom = {
    /**
     * Shut down intercom - remove logged user from intercom and clear history stored in cookies (like messages etc..).
     */
    shutDown: () => {
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    },

    /**
     * Completely Hide intercom
     */
    hideLauncher: () => {
        if (window.Intercom) {
            window.Intercom('hide');
            window.Intercom('update', {
                hide_default_launcher: true
            });
        }
    },

    /**
     * Show intercom
     */
    showLauncher: () => {
        if (window.Intercom) {
            window.Intercom('update', {
                hide_default_launcher: false
            });
        }
    },

    /**
     * Update logged user to intercom. Use already logged if user is not passed to function.
     *
     * @param {object} user
     */
    updateUser: async (user = null) => {
        if (!user) {
            user = await AuthApi.getMe();
        }

        const config = {
            name: user.displayName, // Full name
            email: user.email.address, // Email address
            created_at: user.createdAt.valueOf() // Signup date as a Unix timestamp
        };

        const company = await getCompanyDetails();
        if (company) {
            config.company = company;
        }

        if (user) {
            Intercom.update(config);
        }
    },

    /**
     * Calling the update method with some new user data will trigger the JavaScript to look for new messages that should be displayed.
     *
     * @param config
     */
    update: config => {
        if (window.Intercom) {
            window.Intercom('update', {
                ...config,
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                alignment: ALIGNMENT_LEFT
            });
        }
    },

    /**
     * Boot intercom.
     *
     * @param {object} config
     */
    boot: config => {
        if (window.Intercom) {
            window.Intercom('boot', {
                ...config,
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                alignment: ALIGNMENT_LEFT
            });
        }
    }
};

export default Intercom;
