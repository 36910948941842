import Api from './config';

export const listClinics = async queryParam =>
    await Api.request({
        method: 'GET',
        url: `/clinics${queryParam ? `?${queryParam}` : ''}`
    });

export const listClinicsSoloPractitioners = async () =>
    await Api.request({
        method: 'GET',
        url: '/clinics/solopractitioners'
    });

export const getClinic = async id =>
    await Api.request({
        method: 'GET',
        url: `/clinics/${id}`
    });

export const createClinic = async data =>
    await Api.request({
        method: 'POST',
        url: '/clinics',
        data
    });

export const updateClinic = async (id, data) =>
    await Api.request({
        method: 'PUT',
        url: `/clinics/${id}`,
        data
    });

export const deleteClinic = async id =>
    await Api.request({
        method: 'DELETE',
        url: `/clinics/${id}`
    });

export const archiveClinic = async id =>
    await Api.request({
        method: 'PUT',
        url: `/clinics/archive/${id}`
    });

export const listActiveClinics = async () => {
    return await Api.request({
        method: 'GET',
        url: '/active-clinics'
    });
};
