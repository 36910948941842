import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    withStyles,
    Box,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TablePagination,
    Button
} from '@material-ui/core';
import { StyledTableRow } from '../StyledTableRow';
import { accountViewStyles as styles } from './styles';

import { getGiftcardsHistory } from '../../../../actions/giftcardActions';
import { toLocaleString } from '../../../../collums-components/helpers';
import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';
import { loadInvoice } from '../../../../actions/invoiceActions';
import NotFound from '../../../common/NotFound';

const tableHeader = [
    { name: 'Date/Time' },
    { name: 'Invoice' },
    { name: 'Code' },
    { name: 'Amount' },
    { name: 'Current Balance' },
    { name: 'Location' }
];

const GiftCardHistoryView = ({ classes }) => {
    const dispatch = useDispatch();
    const giftcardsHistory = useSelector(state => state.giftcard.giftcardsHistory) || [];
    const customer = useSelector(state => state.customer.customer);
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState({
        pageSize: 25,
        page: 0
    });

    useEffect(() => {
        setIsLoading(true);
        dispatch(getGiftcardsHistory(customer.id));
        setIsLoading(false);
        // eslint-disable-next-line
    }, []);

    const renderHeaders = data => {
        return data.map((element, index) => (
            <TableCell key={`${index}-header-cell`} className={classes.tableHeader}>
                {element.name}
            </TableCell>
        ));
    };

    const openInvoice = invoice => {
        dispatch(loadInvoice(invoice.id));
    };

    const renderContent = () => {
        return giftcardsHistory.map((element, index) => (
            <StyledTableRow key={`${index}-body-table-row`}>
                <TableCell className={classes.tableBodyCell}>
                    {element.createdAt.format('DD/MM/YYYY - HH:mm')}
                </TableCell>
                <TableCell className={classes.tableBodyCell} align="left">
                    {element.reason ? (
                        <>{element.reason}</>
                    ) : (
                        <Button
                            color="primary"
                            className={classes.invoiceButton}
                            onClick={() => openInvoice(element.invoice)}
                        >
                            {element.invoice?.originalCode || element.invoice?.code}
                        </Button>
                    )}
                </TableCell>
                <TableCell className={`${classes.tableBodyCell}`} align="left">
                    {element.giftCard.code}
                </TableCell>
                <TableCell className={`${classes.tableBodyCell}`} align="left">
                    {toLocaleString(element.balance - element.prevBalance)}
                </TableCell>
                <TableCell className={classes.tableBodyCell} align="left">
                    {toLocaleString(element.balance)}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                    {element.giftCard?.universalUse ? 'All locations' : element.giftCard?.saleLocation?.accountName}
                </TableCell>
            </StyledTableRow>
        ));
    };

    return (
        <>
            {giftcardsHistory.length < 1 && <NotFound />}
            {isLoading && <LoadingScreen />}
            {giftcardsHistory.length > 0 && (
                <Box m={4}>
                    <Box>
                        <Table className={classes.table} size="medium" stickyHeader>
                            <TableHead>
                                <TableRow>{renderHeaders(tableHeader)}</TableRow>
                            </TableHead>
                            <TableBody>{renderContent()}</TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={giftcardsHistory ? giftcardsHistory.length : 0}
                            rowsPerPage={filter.pageSize}
                            page={filter.page}
                            onChangePage={(e, page) => setFilter({ ...filter, page })}
                            onChangeRowsPerPage={e => setFilter({ ...filter, pageSize: e.target.value, page: 0 })}
                            classes={{ root: classes.tablePagination }}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

GiftCardHistoryView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GiftCardHistoryView);
