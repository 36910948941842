import * as actions from '../actions/actionTypes';

const defaultState = {
    appointments: {
        appointments: [],
        metas: {}
    },
    customer: null,
    fetchingAppointments: false,
    loading: false,
    fetchingCustomer: false,
    fetchingTreatmentNotes: false,
    messages: [],
    treatmentNotes: [],
    customerCourses: []
};

const customerReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.LOAD_CUSTOMER_REQUEST:
            return {
                ...state,
                fetchingCustomer: true
            };
        case actions.CLEAR_CUSTOMER:
            return defaultState;

        case actions.LOAD_CUSTOMER_SUCCESS:
            return {
                ...state,
                fetchingCustomer: false,
                customer: action.payload
            };
        case actions.LOAD_CUSTOMER_APPOINTMENTS_REQUEST:
            return {
                ...state,
                fetchingAppointments: true
            };
        case actions.LOAD_CUSTOMER_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                fetchingAppointments: false,
                appointments: action.payload
            };
        case actions.UPDATE_USER_ACCOUNT_BALANCE:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    ...action.payload
                }
            };
        case actions.UPDATE_USER_RAW_ACCOUNT_BALANCE:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    accountBalance: action.payload
                }
            };
        case actions.UPDATE_GP_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.UPDATE_GP_SUCCESS:
            return {
                ...state,
                loading: false,
                customer: {
                    ...state.customer,
                    ...action.payload
                }
            };
        case actions.UPDATE_INSURANCE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.UPDATE_INSURANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                customer: {
                    ...state.customer,
                    insurance: action.payload
                }
            };
        case actions.UPDATE_KIN_OF_EMERGENCY_SUCCESS:
            return {
                ...state,
                loading: false,
                customer: {
                    ...state.customer,
                    kinOfEmergency: action.payload
                }
            };
        case actions.UPDATE_CUSTOMER_IN_CUSTOMER_VIEW_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.UPDATE_CUSTOMER_IN_CUSTOMER_VIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                customer: action.payload
            };
        case actions.FETCH_CUSTOMER_MESSAGES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.FETCH_CUSTOMER_MESSAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                messages: action.payload
            };
        case actions.FETCH_SEARCH_TREATMENT_NOTES_REQUEST:
            return {
                ...state,
                fetchingTreatmentNotes: true
            };
        case actions.FETCH_SEARCH_TREATMENT_NOTES_SUCESS:
            return {
                ...state,
                fetchingTreatmentNotes: false,
                treatmentNotes: action.payload
            };
        case actions.CREATE_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.CREATE_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case actions.FETCH_CUSTOMER_COURSES:
            return {
                ...state,
                customerCourses: action.payload
            };
        default:
            return state;
    }
};

export default customerReducer;
