import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IconButton, makeStyles, TableCell, TableRow, TextField } from '@material-ui/core';
import CustomAutoComplete from '../../../forms/AutoComplete';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { LHR_TREATMENT_AREAS, LHR_SIDES, LHR_SKIN_TYPES, LHR_COOLING } from '../../../../../collums-constants';
import CancelContinueModal from '../../../../common/CancelContinueModal';

function LaserTableRow({
    id,
    classes,
    row,
    index,
    setIsCurrentlyEditingNote,
    setAutocompleteValue,
    setValue,
    deleteRow,
    copyRow,
    disabled,
    hideOptions
}) {
    const [modalVisible, setModalVisible] = useState(false);

    const rxOptions = new Array(15).fill('').map((_, index) => index + 1);
    const impOptions = new Array(11).fill('').map((_, index) => `${index * 10}%`);

    const useStyles = makeStyles({
        disabledSelect: {
            color: 'black !important'
        },
        disabledTextField: {
            '& .MuiOutlinedInput-root.Mui-disabled': {
                color: 'black !important'
            },
            '& .MuiInputBase-input.Mui-disabled': {
                color: 'black !important'
            }
        },
        disabledCheckbox: {
            '& .MuiCheckbox-root.Mui-disabled': {
                color: 'black !important'
            },
            '& .MuiSvgIcon-root': {
                color: 'black !important'
            }
        }
    });

    const currentClasses = useStyles();

    impOptions.unshift('n/a');

    return (
        <TableRow id={id} tabIndex={-1}>
            {modalVisible && (
                <CancelContinueModal
                    id="warning-modal"
                    title={'Warning'}
                    setOpen={() => {
                        setModalVisible(false);
                    }}
                    contentText={'Are you sure you want to Delete this Row?'}
                    onContinue={() => {
                        setModalVisible(false);
                        deleteRow();
                    }}
                />
            )}

            <TableCell className={classes.cell} style={{ width: '10%' }}>
                <CustomAutoComplete
                    id="area-selector"
                    isSelect
                    options={Object.values(LHR_TREATMENT_AREAS)}
                    value={row.area || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'area')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                    disabledNotGrayed={disabled}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <CustomAutoComplete
                    id="rx-selector"
                    isSelect
                    options={rxOptions}
                    value={row.rx || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'rx')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                    disabledNotGrayed={disabled}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <CustomAutoComplete
                    id="area-selector"
                    isSelect
                    options={impOptions}
                    value={row.imp || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'imp')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                    disabledNotGrayed={disabled}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '7.5%' }}>
                <CustomAutoComplete
                    id="fluence"
                    isSelect
                    options={Object.values(LHR_SIDES)}
                    value={row.side || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'side')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                    disabledNotGrayed={disabled}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <CustomAutoComplete
                    id="skin-type-selector"
                    isSelect
                    options={LHR_SKIN_TYPES}
                    value={row.skinType || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'skinType')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                    disabledNotGrayed={disabled}
                />
            </TableCell>

            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <TextField
                    variant="outlined"
                    onChange={e => setValue(e, index)}
                    value={row.handpiece || ''}
                    name="handpiece"
                    fullWidth
                    disabled={disabled}
                    className={disabled ? currentClasses.disabledTextField : ''}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <TextField
                    variant="outlined"
                    onChange={e => {
                        const value = e.target.value;
                        if (value < 0 || isNaN(value)) {
                            return;
                        }
                        const splitValue = value?.split('.') || [];
                        if (splitValue?.length > 2) {
                            return;
                        }
                        if (splitValue[1]?.length > 2) {
                            return;
                        }
                        setValue(e, index);
                    }}
                    value={row.fluence || ''}
                    name="fluence"
                    fullWidth
                    disabled={disabled}
                    className={disabled ? currentClasses.disabledTextField : ''}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <TextField
                    variant="outlined"
                    onChange={e => {
                        // const value = e.target.value;
                        // if (value < 0 || isNaN(value)) {
                        //     return;
                        // }
                        // const splitValue = value?.split('.') || [];
                        // if (splitValue?.length > 2) {
                        //     return;
                        // }
                        // if (splitValue[1]?.length > 2) {
                        //     return;
                        // }
                        setValue(e, index);
                    }}
                    value={row.pw || ''}
                    name="pw"
                    fullWidth
                    disabled={disabled}
                    className={disabled ? currentClasses.disabledTextField : ''}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <TextField
                    variant="outlined"
                    onChange={e => setValue(e, index)}
                    value={row.freq || ''}
                    name="freq"
                    fullWidth
                    disabled={disabled}
                    className={disabled ? currentClasses.disabledTextField : ''}
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <TextField
                    variant="outlined"
                    onChange={e => {
                        const value = e.target.value;
                        if (value < 0 || isNaN(value)) {
                            return;
                        }
                        const splitValue = value?.split('.') || [];
                        if (splitValue?.length > 2) {
                            return;
                        }
                        if (splitValue[1]?.length > 2) {
                            return;
                        }
                        setValue(e, index);
                    }}
                    onBlur={() => {
                        const target = {
                            name: 'shots',
                            value: String(Number(row.shots))
                        };
                        if (!Number(target.value)) return;
                        setValue({ target }, index);
                        setIsCurrentlyEditingNote(true);
                    }}
                    disabled={disabled}
                    className={disabled ? currentClasses.disabledTextField : ''}
                    value={row.shots || ''}
                    name="shots"
                />
            </TableCell>
            <TableCell className={classes.cell} style={{ width: '5%' }}>
                <CustomAutoComplete
                    id="cooling-selector"
                    isSelect
                    options={Object.values(LHR_COOLING)}
                    value={row.cooling || null}
                    onChange={(_, value) => setAutocompleteValue(value, index, 'cooling')}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    disabled={disabled}
                    disabledNotGrayed={disabled}
                />
            </TableCell>
            {!hideOptions && (
                <>
                    <TableCell className={classes.cell} style={{ width: '5%' }}>
                        <IconButton
                            disabled={disabled}
                            onClick={() => {
                                setModalVisible(true);
                            }}
                        >
                            <DeleteIcon style={{ fontSize: '15pt' }}></DeleteIcon>
                        </IconButton>
                    </TableCell>
                    <TableCell className={classes.cell} style={{ width: '5%' }}>
                        <IconButton disabled={disabled} onClick={copyRow}>
                            <FileCopyIcon style={{ fontSize: '15pt' }}></FileCopyIcon>
                        </IconButton>
                    </TableCell>
                </>
            )}
        </TableRow>
    );
}
LaserTableRow.propTypes = {
    id: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    setAutocompleteValue: PropTypes.func.isRequired,
    setIsCurrentlyEditingNote: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    deleteRow: PropTypes.func.isRequired,
    copyRow: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    hideOptions: PropTypes.bool
};
export default LaserTableRow;
