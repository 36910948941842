import styled from 'styled-components';
import { Box, TableCell as MUITableCell, TableRow as TableRowUI, Typography, Button, Grid } from '@material-ui/core';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const Asterisk = styled(FiberManualRecordIcon)`
    height: 14px !important;
`;

export const Title = styled(Typography)`
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    font-family: Roboto, Arial, 'sans-serif' !important;
`;

export const ContainerNumber = styled(Box)`
    p {
        font-size: 14px;
        font-weight: bold;
        font-family: ;
    }
    p:not(:last-child) {
        margin-right: 10px;
    }
`;

export const PINStaffContainer = styled.div`
    height: 100%;
    width: 100%;
    justify-content: center;
    overflow: auto;
    align-items: center;
    z-index: 10000;
    background-color: white;
`;

export const LoginAvatar = styled.div`
    width: 15rem;
    height: 15rem;
    cursor: pointer;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.5s ease-in-out;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 0.5rem;
    padding: 0.5rem;

    padding-bottom: 0.3rem;
    padding-top: 0.3rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &&:hover {
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    }
`;

export const Avatar = styled.img`
    display: block;
    width: 10rem;
    height: 10rem;
    transition: all 0.3s ease-in-out;

    margin-bottom: 0.2rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;

    &&:hover {
        transform: scale(1.05);
    }
`;

export const PINContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: auto;
    align-items: center;
    z-index: 10000;
    background-color: white;
`;

export const TableRow = styled(TableRowUI)`
    td:not(.disabled) {
        text-align: center !important;
        cursor: pointer;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    td:hover:not(.disabled) {
        background-color: rgba(224, 224, 224, 1);
    }
    td {
        border: 1px solid rgba(224, 224, 224, 1);
    }
    td > button:hover {
        background-color: transparent;
    }
`;

export const TableCell = styled(MUITableCell)`
    font-size: 14px !important;
    font-family: Roboto, Arial, 'sans-serif';
    border-radius: 0.5rem;
    ${props => (props.noPadding ? 'padding: 0;' : '')};
`;

export const PinPadButtonContainer = styled(Grid)`
    min-width: 44px;
    min-height: 44px;
    font-size: 14px !important;
    font-family: Roboto, Arial, 'sans-serif';
    ${props => (props.noPadding ? 'padding: 0;' : '')};
`;

export const PinPadButton = styled(Button)`
    min-height: 64px !important;
    height: 100% !important;
    width: 100% !important;
    border-radius: 0.5rem !important;
    background-color: lightgray !important;
    text-transform: none !important;
    font-weight: 400 !important;
    font-family: Roboto, Arial, Sans-serif !important;

    span {
        font-size: 22px !important;
    }

    @media (hover: none) {
        &&:hover {
            background-color: lightgray !important;
        }
    }

    &:focus {
        background-color: lightgray !important;
    }

    &:active {
        background-color: darkgray !important;
    }
`;

export const PinCardTitle = styled(Typography)`
    font-size: 14px !important;
    font-family: Roboto, Arial, 'sans-serif' !important;
`;
