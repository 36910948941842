import Axios from './config';

class MembershipApi {
    static createCustomerMembership(data) {
        return Axios({
            method: 'POST',
            url: '/customer-membership',
            data
        })
            .then(res => {
                if (res.data.success) {
                    return { data: res.data, success: true };
                } else {
                    return {
                        success: false,
                        message: 'Membership could not be created. Please contact Collums Support'
                    };
                }
            })
            .catch(err => {
                if (Array.isArray(err?.response?.data?.validation?.keys)) {
                    const mapper = {
                        name: 'Name',
                        firstPayment: 'Date of first payment',
                        amount: 'Monthly amount',
                        customer: 'Customer',
                        numberOfPayments: 'No of monthly payments'
                    };

                    const invalidFields = err?.response?.data?.validation?.keys.map(field => mapper[field]);

                    let message = `Field${invalidFields.length === 1 ? '' : 's'} ${invalidFields.join(
                        ','
                    )} must contain proper value`;
                    return { success: false, message };
                }
            });
    }

    static voidDirectDebit(data) {
        return Axios({
            method: 'POST',
            url: '/coherent/direct-debit-status',
            data: { ...data, status: 'void' }
        })
            .then(res => {
                if (res.data.success) {
                    return { data: res.data, success: true };
                } else {
                    return {
                        success: false,
                        message: 'Direct debit was cancelled'
                    };
                }
            })
            .catch(() => {
                return { success: false, message: 'Direct debit cancel failed' };
            });
    }
}

export const listCustomerMemberships = async ({ customer, order, page, rowsPerPage }) => {
    const result = await Axios({
        method: 'GET',
        url: `/direct-debits/customer?customerId=${customer}&order=${order}&page=${page}&rowsPerPage=${rowsPerPage}`
    });

    return result.data;
};

export default MembershipApi;
