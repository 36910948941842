export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SET_POPUPS = 'SET_POPUPS';
export const POPUP_TYPE = 'POPUP_TYPE';
export const ACTION_POST_POPUP = 'ACTION_POST_POPUP';

export const CLEAR_NOTIFICATION_MESSAGE = 'CLEAR_NOTIFICATION_MESSAGE';

export const LOAD_DAY_SCHEDULE_SUCCESS_WITHOUT_RELOAD = 'LOAD_DAY_SCHEDULE_SUCCESS_WITHOUT_RELOAD';

export const LOAD_DAY_SCHEDULE_SUCCESS = 'LOAD_DAY_SCHEDULE_SUCCESS';
export const LOAD_DAY_SCHEDULE_REQUEST = 'LOAD_DAY_SCHEDULE_REQUEST';

export const PERSIST_LEAVE_SUCCESS = 'PERSIST_LEAVE_SUCCESS';

export const PERSIST_APPOINTMENT_REQUEST = 'PERSIST_APPOINTMENT_REQUEST';
export const PERSIST_APPOINTMENT_SUCCESS = 'PERSIST_APPOINTMENT_SUCCESS';

export const REMOVE_APPOINTMENT_REQUEST = 'REMOVE_APPOINTMENT_REQUEST';
export const REMOVE_APPOINTMENT_SUCCESS = 'REMOVE_APPOINTMENT_SUCCESS';

export const SEARCH_CUSTOMER_REQUEST = 'SEARCH_CUSTOMER_REQUEST';
export const SEARCH_CUSTOMER_SUCCESS = 'SEARCH_CUSTOMER_SUCCESS';

export const PERSIST_CUSTOMER_REQUEST = 'PERSIST_CUSTOMER_REQUEST';
export const PERSIST_CUSTOMER_SUCCESS = 'PERSIST_CUSTOMER_SUCCESS';

export const SEARCH_PRACTITIONER_REQUEST = 'SEARCH_PRACTITIONER_REQUEST';
export const SEARCH_PRACTITIONER_SUCCESS = 'SEARCH_PRACTITIONER_SUCCESS';

export const FETCHED_PRACTITIONER = 'FETCHED_PRACTITIONER';
export const CREATED_PRACTITIONER_SCHEDULE = 'CREATED_PRACTITIONER_SCHEDULE';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';

export const FETCH_SERVICES_REQUEST = 'PERSIST_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'PERSIST_SERVICES_SUCCESS';

export const FETCH_ROOMS_REQUEST = 'PERSIST_ROOMS_REQUEST';
export const FETCH_ROOMS_SUCCESS = 'PERSIST_ROOMS_SUCCESS';
export const FETCH_ROOMS_APPT_SUCCESS = 'PERSIST_ROOMS_APPT_SUCCESS';
export const FILTER_ROOMS = 'FILTER_ROOMS';

export const FETCH_CUSTOMER_COURSES = 'FETCH_CUSTOMER_COURSES';

export const FETCH_GIFTCARD = 'FETCH_GIFTCARD';
export const FETCH_GIFTCARD_HISTORY = 'FETCH_GIFTCARD_HISTORY';
export const FETCH_GIFTCARD_FILTERED_BY_CUSTOMER = 'FETCH_GIFTCARD_FILTERED_BY_CUSTOMER';

export const ZOOM_SET = 'ZOOM_SET';
export const ZOOM_IN = 'ZOOM_IN';
export const ZOOM_OUT = 'ZOOM_OUT';

export const UPDATE_APPOINTMENT_NOTES_REQUEST = 'UPDATE_APPOINTMENT_NOTES_REQUEST';
export const UPDATE_APPOINTMENT_NOTES = 'UPDATE_APPOINTMENT_NOTES';

export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';

export const SET_HIDE_CUSTOMER_INFO = 'SET_HIDE_CUSTOMER_INFO';

export const PERSIST_PRACTITIONER_LIST_ORDER = 'PERSIST_PRACTITIONER_LIST_ORDER';

export const LOAD_CUSTOMER = 'LOAD_CUSTOMER';
export const LOAD_CUSTOMER_REQUEST = 'LOAD_CUSTOMER_REQUEST';
export const LOAD_CUSTOMER_SUCCESS = 'LOAD_CUSTOMER_SUCCESS';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';

export const UPDATE_USER_ACCOUNT_BALANCE = 'UPDATE_USER_ACCOUNT_BALANCE';
export const UPDATE_USER_RAW_ACCOUNT_BALANCE = 'UPDATE_USER_RAW_ACCOUNT_BALANCE';

export const LOAD_CUSTOMER_APPOINTMENTS = 'LOAD_CUSTOMER_APPOINTMENTS';
export const LOAD_CUSTOMER_APPOINTMENTS_REQUEST = 'LOAD_CUSTOMER_APPOINTMENTS_REQUEST';
export const LOAD_CUSTOMER_APPOINTMENTS_SUCCESS = 'LOAD_CUSTOMER_APPOINTMENTS_SUCCESS';

export const UPDATE_GP = 'UPDATE_GP';
export const UPDATE_GP_REQUEST = 'UPDATE_GP_REQUEST';
export const UPDATE_GP_SUCCESS = 'UPDATE_GP_SUCCESS';

export const UPDATE_INSURANCE = 'UPDATE_INSURANCE';
export const UPDATE_INSURANCE_REQUEST = 'UPDATE_INSURANCE_REQUEST';
export const UPDATE_INSURANCE_SUCCESS = 'UPDATE_INSURANCE_SUCCESS';
export const UPDATE_KIN_OF_EMERGENCY_REQUEST = 'UPDATE_KIN_OF_EMERGENCY_REQUEST';
export const UPDATE_KIN_OF_EMERGENCY_SUCCESS = 'UPDATE_KIN_OF_EMERGENCY_SUCCESS';

export const UPDATE_CUSTOMER_IN_CUSTOMER_VIEW_REQUEST = 'UPDATE_CUSTOMER_IN_CUSTOMER_VIEW_REQUEST';
export const UPDATE_CUSTOMER_IN_CUSTOMER_VIEW_SUCCESS = 'UPDATE_CUSTOMER_IN_CUSTOMER_VIEW_SUCCESS';

export const SHOW_SEARCH_CUSTOMER_MODAL = 'SHOW_SEARCH_CUSTOMER_MODAL';
export const HIDE_SEARCH_CUSTOMER_MODAL = 'HIDE_SEARCH_CUSTOMER_MODAL';

export const SET_CHECK_IN_METADATA = 'SET_CHECK_IN_METADATA';

export const LOAD_PRINT_APPOINTMENT = 'LOAD_PRINT_APPOINTMENT';
export const CLOSE_PRINT_APPOINTMENT = 'CLOSE_PRINT_APPOINTMENT';

export const LOAD_APPOINTMENT_LOG = 'LOAD_APPOINTMENT_LOG';
export const CLOSE_APPOINTMENT_LOG = 'CLOSE_APPOINTMENT_LOG';

export const LOAD_COURSE_MODAL = 'LOAD_COURSE_MODAL';
export const CLOSE_COURSE_MODAL = 'CLOSE_COURSE_MODAL';

export const LOAD_COURSE_HISTORY_MODAL = 'LOAD_COURSE_HISTORY_MODAL';
export const CLOSE_COURSE_HISTORY_MODAL = 'CLOSE_COURSE_HISTORY_MODAL';

export const SHOW_JOURNEY_MODAL = 'SHOW_JOURNEY_MODAL';
export const HIDE_JOURNEY_MODAL = 'HIDE_JOURNEY_MODAL';

export const SHOW_CREATE_CUSTOMER_MODAL = 'SHOW_CREATE_CUSTOMER_MODAL';
export const HIDE_CREATE_CUSTOMER_MODAL = 'HIDE_CREATE_CUSTOMER_MODAL';

export const SHOW_PRACTITIONER_REORDER_MODAL = 'SHOW_PRACTITIONER_REORDER_MODAL';
export const HIDE_PRACTITIONER_REORDER_MODAL = 'HIDE_PRACTITIONER_REORDER_MODAL';

export const SET_STORE_STATE = 'SET_STORE_STATE';

export const SET_LIST_SHOULD_UPDATE = 'SET_LIST_SHOULD_UPDATE';

export const SET_INVOICE_REFUND_OPEN = 'SET_INVOICE_REFUND_OPEN';

export const SET_TREATMENT = 'SET_TREATMENT';
export const SET_TREATMENT_ID = 'SET_TREATMENT_ID';
export const SET_TREATMENT_ATTRIBUTE = 'SET_TREATMENT_ATTRIBUTE';
export const SET_TREATMENT_FORMS = 'SET_TREATMENT_FORMS';
export const SET_TREATMENT_CANVAS_MODELS = 'SET_TREATMENT_CANVAS_MODELS';
export const SET_TREATMENT_IS_OPEN = 'SET_TREATMENT_IS_OPEN';
export const SET_IS_TREATMENT_DIAGRAM_OPEN = 'SET_IS_TREATMENT_DIAGRAM_OPEN';
export const SET_CANVAS = 'SET_CANVAS';
export const SET_SELECTED_TREATMENT_CANVAS_MODEL = 'SET_SELECTED_TREATMENT_CANVAS_MODEL';
export const SET_CANVAS_METADATA = 'SET_CANVAS_METADATA';
export const SET_IS_TREATMENT_DIAGRAM_LOCKED = 'SET_IS_TREATMENT_DIAGRAM_LOCKED';
export const SET_PRACTITIONER_NOTE_ATTRIBUTES = 'SET_PRACTITIONER_NOTE_ATTRIBUTES';
export const SET_TREATMENT_ACCESSED_THROUGHT = 'SET_TREATMENT_ACCESSED_THROUGHT';
export const SET_TREATMENT_MODALS = 'SET_TREATMENT_MODALS';
export const SET_TREATMENT_DIAGRAM_METADATA = 'SET_TREATMENT_DIAGRAM_METADATA';
export const SET_TREATMENT_NOTES_METADATA = 'SET_TREATMENT_NOTES_METADATA';
export const SET_BOOKED_APPOINTMENT = 'SET_BOOKED_APPOINTMENT';
export const SET_TREATMENT_CUSTOMER = 'SET_TREATMENT_CUSTOMER';
export const SET_TREATMENT_CUSTOMER_ATTRIBUTE = 'SET_TREATMENT_CUSTOMER_ATTRIBUTE';
export const SET_TREATMENT_TYPE = 'SET_TREATMENT_TYPE';
export const PUSH_TREATMENT_UNIT = 'PUSH_TREATMENT_UNIT';
export const SET_TREATMENT_UNIT_AVAILABLE_ATTRIBUTES = 'SET_TREATMENT_UNIT_ATTRIBUTES';
export const SET_SELECTED_TREATMENT_UNIT_ATTRIBUTE = 'SET_SELECTED_TREATMENT_UNIT_ATTRIBUTE';

export const UPDATE_CUSTOMER_IN_TREATMENT_VIEW_SUCCESS = 'UPDATE_CUSTOMER_IN_TREATMENT_VIEW_SUCCESS';
export const UPDATE_CUSTOMER_IN_TREATMENT_VIEW_REQUEST = 'UPDATE_CUSTOMER_IN_TREATMENT_VIEW_REQUEST';

export const FETCH_TREATMENT_CATEGORIES_SUCCESS = 'FETCH_TREATMENT_CATEGORIES_SUCCESS';
export const FETCH_TREATMENT_CATEGORIES_REQUEST = 'FETCH_TREATMENT_CATEGORIES_REQUEST';

export const FETCH_TREATMENTS_SUCCESS = 'FETCH_TREATMENTS_SUCCESS';
export const FETCH_TREATMENTS_REQUEST = 'FETCH_TREATMENTS_REQUEST';

export const FETCH_TREATMENT_SUCCESS = 'FETCH_TREATMENT_SUCCESS';
export const FETCH_TREATMENT_REQUEST = 'FETCH_TREATMENT_REQUEST';

export const PERSIST_TREATMENT_REQUEST = 'PERSIST_TREATMENT_REQUEST';
export const PERSIST_TREATMENT_SUCCESS = 'PERSIST_TREATMENT_SUCCESS';

export const TREATMENT_NOTE_SET = 'TREATMENT_NOTE_SET';

export const SET_TREATMENT_NOTES = 'SET_TREATMENT_NOTES';

export const FETCH_CUSTOMER_MESSAGES_REQUEST = 'FETCH_CUSTOMER_MESSAGES_REQUEST';
export const FETCH_CUSTOMER_MESSAGES_SUCCESS = 'FETCH_CUSTOMER_MESSAGES_SUCCESS';

export const FETCH_SEARCH_TREATMENT_NOTES_SUCESS = 'FETCH_SEARCH_TREATMENT_NOTES_SUCESS';
export const FETCH_SEARCH_TREATMENT_NOTES_REQUEST = 'FETCH_SEARCH_TREATMENT_NOTES_REQUEST';

export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';

export const SET_SHOW_ZOOM = 'SET_SHOW_ZOOM';

export const CALENDAR_FETCH_ERROR = 'CALENDAR_FETCH_ERROR';

export const UPDATE_TREATMENT_FORM = 'UPDATE_TREATMENT_FORM';
export const CLEAN_TREATMENT_FORM = 'CLEAN_TREATMENT_FORM';

export const LOAD_INVOICE = 'LOAD_INVOICE';
export const CLOSE_INVOICE_MODAL = 'CLOSE_INVOICE_MODAL';
export const SET_AVAILABLE_PRODUCTS = 'SET_AVAILABLE_PRODUCTS';
export const SET_USED_PRODUCTS = 'SET_USED_PRODUCTS';

export const CHANGE_CLINIC = 'CHANGE_CLINIC';

export const LOAD_ORGANISATION = 'LOAD_ORGANISATION';

export const CANCELING_REASON = 'CANCELING_REASON';

export const SHOW_CONFLICTS_MODAL = 'SHOW_CONFLICTS_MODDAL';
export const HIDE_CONFLICTS_MODAL = 'HIDE_CONFLICTS_MODDAL';

export const OPEN_REFRESH_MODAL = 'OPEN_REFRESH_MODAL';
export const CLOSE_REFRESH_MODAL = 'CLOSE_REFRESH_MODAL';

export const SET_CALENDAR_VIEW_MODE = 'SET_CALENDAR_VIEW_MODE';

export const SET_CALENDAR_DATA = 'SET_CALENDAR_DATA';

export const SET_DRAWER_DATA = 'SET_DRAWER_DATA';
export const SET_DRAWER_EDITING_APPOINTMENT_DATA = 'SET_DRAWER_EDITING_APPOINTMENT_DATA';

export const IS_DATE_CHANGE = 'IS_DATE_CHANGE';
